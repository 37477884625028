import { useState } from "react";
import Courcespop from "../Pictures/Popups/Courcespop";

function Vcourse({
  getContact,
  contactdata,
  DeleteData,
}: {
  getContact: any;
  contactdata: any;
  DeleteData: any;
}) {
  const [openModel, setOpenModel] = useState(false);
  const [UpdateData, setUpdateData] = useState<any>([]);

  return (
    <div className="static mt-5 ">
      <div className="relative  shadow-md sm:rounded-lg">
        {openModel && (
          <Courcespop
            UpdateData={UpdateData}
            getContact={getContact}
            closeModel={setOpenModel}
          />
        )}
        <div className="overflow-scroll scroll">
          <table className="w-full    text-gray-800">
            <thead className="text-sm py-2 text-gray-700 uppercase  bg-green-200 dark:text-gray-600">
              <tr>
                <th align="center" scope="col" className="px-6 py-3">
                  S.N
                </th>
                <th align="center" scope="col" className="px-12 py-3">
                  IMAGE
                </th>
                <th align="center" scope="col" className="px-12 py-3">
                  TITLE
                </th>
                <th align="center" scope="col" className="px-12 py-3">
                  SUB HEADING
                </th>
                <th align="center" scope="col" className="px-12 py-3">
                  DESCRIPTION
                </th>
                <th align="center" scope="col" className="px-12 py-3">
                  FEATURED
                </th>
                <th align="center" scope="col" className="px-12 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {contactdata.map((val: any, i: number) => {
                return (
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <td align="center" className="px-2 py-4">
                      {i + 1}
                    </td>
                    <td align="center" className="px-2 py-4">
                      <img
                        alt="image"
                        src={`https://backend.lophoabroad.com/public/${val.image}`}
                        className="h-12 w-12"
                      />
                    </td>
                    <td align="center" className="px-2 py-4">
                      {val.title}
                    </td>
                    <td align="center" className="px-2 py-4">
                      {val.subheading}
                    </td>
                    <td align="center" className="px-2 py-4">
                      <div
                        className="break-words line-clamp-3 text-xs"
                        dangerouslySetInnerHTML={{ __html: val.description }}
                      />
                    </td>
                    <td align="center" className="px-2 py-4">
                      {val.featured.toString()}
                    </td>
                    <td align="center" className="flex flex-row mt-2 px-2">
                      <button
                        className="bg-blue-600 mr-1 text-white px-4 py-2"
                        onClick={() => {
                          setOpenModel(true);
                          setUpdateData([val]);
                        }}
                      >
                        {" "}
                        Edit
                      </button>
                      <button
                        className="bg-red-600 text-white px-4 py-2"
                        onClick={() => {
                          DeleteData(val.id);
                        }}
                      >
                        {" "}
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Vcourse;
