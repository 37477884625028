import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { HiViewGridAdd } from "react-icons/hi";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-hot-toast";

const initialValues = {
  image: "",
  title: "",
  subtitle: "",
  description: "",
  featured: "",
};

const schema = yup.object().shape({
  // image: yup.string().required('Enter your image'),
  title: yup.string().required("Enter your title"),
  subtitle: yup.string().required("Enter your subtitle"),
  description: yup.string().required("Enter your description"),
  featured: yup.string().required("Enter your featured"),
});

function Country() {
  const form = [
    {
      name: "image",
      label: "Image",
      type: "file",
    },
    {
      name: "title",
      label: "Title",
      type: "text",
    },
    {
      name: "subtitle",
      label: "Sub Title",
      type: "text",
    },

    {
      name: "featured",
      label: "Featured",
      type: "select",
      options: [
        {
          value: "",
          label: "Select",
        },
        {
          value: "true",
          label: "true",
        },
        {
          value: "false",
          label: "false",
        },
      ],
    },
  ];

  return (
    <div className="flex items-center gap-6 flex-col py-4 px-4">
      <div className="flex  flex-col w-full h-fit  ">
        <div className="text-2xl font-semibold "> Section Countries</div>
        <Link
          to={"/viewcountry"}
          className="flex flex-row gap-2 w-fit h-fit items-center py-1 border-b  font-semibold border-gray-400 "
        >
          <button>View</button>
          <span>
            <HiViewGridAdd />
          </span>
        </Link>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(value, { resetForm }) => {
          try {
            const formData = new FormData();
            formData.append("image", value.image);
            formData.append("title", value.title);
            formData.append("subtitle", value.subtitle);
            formData.append("description", value.description);
            formData.append("featured", value.featured);
            axios
              .post(`https://backend.lophoabroad.com/country/`, formData)
              .then((res) => {
                toast.success("Country data sent successfully");
                resetForm();
              })
              .catch((err) => {
                toast.error(" Country data could not be added");
              });
          } catch (error) {
            toast.error("Server Error");
          }
        }}
      >
        {({ setFieldValue, values }) => {
          return (
            <Form className=" h-fit w-full  py-3 gap-4 flex flex-col">
              <div className="grid grid-cols-2 gap-6">
                {form.map((val: any, i: number) => {
                  return (
                    <>
                      <div key={i} className="">
                        {val.type === "file" ? (
                          <div className="flex flex-col gap-2">
                            <label
                              className=" font-semibold text-md"
                              htmlFor={val.name}
                            >
                              {" "}
                              {val.label}
                            </label>
                            <input
                              type="file"
                              className="h-fit w-full px-2 py-2 border-2 border-gray-400"
                              onChange={(e: any) =>
                                setFieldValue(val.name, e.target.files[0])
                              }
                            />
                          </div>
                        ) : val.type === "select" ? (
                          <div className="flex flex-col gap-2">
                            <label
                              className="h-fit  font-semibold text-md"
                              htmlFor={val.name}
                            >
                              {" "}
                              {val.label}
                            </label>
                            <select
                              className="h-fit w-full px-2 py-2 border-2 border-gray-400"
                              name={val.name}
                              onChange={(e) =>
                                setFieldValue(val.name, e.target.value)
                              }
                            >
                              {val.options.map((val: any, i: number) => (
                                <option key={i} value={val.value}>
                                  {val.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        ) : (
                          <div className="flex flex-col gap-2">
                            <label
                              className="h-fit w  font-semibold text-md "
                              htmlFor={val.name}
                            >
                              {" "}
                              {val.label}
                            </label>
                            <Field
                              className="border-2 border-gray-400 py-2 px-2 w-full "
                              type={val.type}
                              name={val.name}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  );
                })}
              </div>

              <div className="flex flex-col gap-4">
                <div>
                  <label
                    className="font-semibold text-md w-36"
                    htmlFor="description"
                  >
                    Description
                  </label>
                </div>
                <ReactQuill
                  className="h-44 w-full overflow-scroll bg-white border border-gray-400 "
                  theme="snow"
                  value={values.description}
                  onChange={(e) => {
                    setFieldValue("description", e);
                  }}
                />
              </div>
              <input
                className="px-8 py-3 rounded-md shadow-md text-md  w-fit h-fit text-white bg-green-600"
                type="submit"
                name="submit"
              />
            </Form>
          );
        }}
      </Formik>

      <div></div>
    </div>
  );
}

export default Country;
