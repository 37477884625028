import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { FiFilter } from "react-icons/fi";
import { HiViewGridAdd } from "react-icons/hi";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import * as Yup from "yup";

const schema = Yup.object().shape({
  Name: Yup.string().required("Required"),
  Image: Yup.string().required("Required"),
  Description: Yup.string().required("Required"),
});

function Testimonials() {
  return (
    <div className="flex flex-col gap-4 px-4 py-4">
      <div className="text-xl font-semibold"> Our Testimonials</div>
      <div className="flex flex-row gap-8">
        {" "}
        <div className="flex flex-row gap-2 text-lg items-center font-semibold">
          <FiFilter /> Filter
        </div>
        <Link
          to={"/vtestimonials"}
          className="flex flex-row gap-2 items-center text-lg  px-10 font-semibold"
        >
          <button>View</button>
          <span>
            <HiViewGridAdd />
          </span>
        </Link>
      </div>
      <div className="flex flex-col px-4 h-fit py-4">
        <div>
          <Formik
            initialValues={{
              Name: "",
              Image: "",
              Description: "",
            }}
            validationSchema={schema}
            onSubmit={(values, { resetForm }) => {
              console.log(values);

              try {
                const formData = new FormData();
                formData.append("Name", values.Name);
                formData.append("Image", values.Image);
                formData.append("Description", values.Description);
                axios
                  .post(
                    `https://backend.lophoabroad.com/testimonials/`,
                    formData
                  )
                  .then((res) => {
                    toast.success("Testimonials data sent successfully");
                    resetForm();
                  })
                  .catch((err) => {
                    toast.error("Testimonials data could not be added");
                  });
              } catch (error) {
                toast.error("Server Error");
              }
            }}
          >
            {({ handleSubmit, setFieldValue, values }) => {
              return (
                <Form
                  className="flex h-full  py-4  flex-col gap-4"
                  onSubmit={handleSubmit}
                >
                  <div className="grid grid-cols-2 gap-2">
                    <div className="flex  flex-col gap-2 ">
                      <label className="text-gray-800 text-md  font-semibold">
                        Name
                      </label>
                      <Field
                        name="Name"
                        id="Name"
                        type="text"
                        className="bg-white py-2 w-full px-2 border border-gray-400 "
                        placeholder="Name"
                        required
                      />
                      <ErrorMessage
                        className="text-red-500 flex flex-row px-4 "
                        name="Name"
                        component={"div"}
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <label className=" text-md  font-semibold"> Image</label>
                      <input
                        name="Image"
                        id="Image"
                        type="file"
                        onChange={(e: any) => {
                          setFieldValue("Image", e.target.files[0]);
                        }}
                        className="bg-white border px-2 py-2 w-full "
                        required
                      />
                      <ErrorMessage
                        className="text-red-500 flex flex-row px-4 "
                        name="Image"
                        component={"div"}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 ">
                    <label className="text-gray-800 text-md font-semibold">
                      {" "}
                      Description
                    </label>
                    <ReactQuill
                      className="h-44 w-full overflow-scroll bg-white  border border-gray-400 "
                      theme="snow"
                      value={values.Description}
                      onChange={(e) => {
                        setFieldValue("Description", e);
                      }}
                    />
                    <ErrorMessage
                      className="text-red-500 flex flex-row px-4 "
                      name="Description"
                      component={"div"}
                    />
                  </div>

                  <div className="w-full justify-center">
                    <input
                      type="submit"
                      className="h-fit w-fit px-10 py-2 bg-green-500 text-white"
                      alt="submit"
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>

        {/* <div className=" flex flex-col px-14 py-4">
          <div className="h-2/3 py-5 gap-2 flex flex-col items-center w-full bg-gradient-to-r from-transparent to-blue-400 relative rounded-full ">
            <div className="text-xl font-bold text-gray-700 font-mono mt-6">
              Our Mission
            </div>
            <div className="w-72 h-fit text-gray-500 text-center ">
              {" "}
              We look forward to the best service and counselling. We assure you
              the future you want is achievable.{" "}
            </div>
            <div className="bg-gradient-to-r from-blue-200 to-transparent justify-center gap-2 h-64 w-fit px-16 flex flex-col items-center  absolute -right-5 -bottom-48 rounded-full">
              <div className="text-xl font-bold text-gray-700 font-mono">
                {" "}
                Our Vision
              </div>
              <div className="w-72 h-fit text-gray-500 text-center">
                {" "}
                Our ultimate goalis to make our students satisfied with our
                work. We aim a better future for our students
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Testimonials;
