import { useState } from "react";
import { Link } from "react-router-dom";
import { FiFilter } from "react-icons/fi";
import { HiOutlineViewGridAdd } from "react-icons/hi";
import { Field, Formik, Form, ErrorMessage } from "formik";
import axios from "axios";
import * as yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-hot-toast";

const schema = yup.object().shape({
  name: yup.string().required("Énter your name"),
  image: yup.string().required("Énter Image"),
  position: yup.string().required("Énter position"),
  description: yup.string().required(" Enter description"),
});

function Teams() {
  const [image, setImage] = useState<File>();
  return (
    <Formik
      initialValues={{
        name: "",
        image: "",
        position: "",
        description: "",
      }}
      validationSchema={schema}
      onSubmit={(values, { resetForm }) => {
        console.log(values);

        try {
          const formData = new FormData();
          formData.append("name", values.name);
          formData.append("image", values.image);
          formData.append("position", values.position);
          formData.append("description", values.description);
          axios
            .post(`https://backend.lophoabroad.com/teams/`, formData)
            .then((res) => {
              toast.success("Teams data sent successfully");
              resetForm();
            })
            .catch((err) => {
              toast.error(" Teams data could not be added");
            });
        } catch (error) {
          toast.error("Server Error");
        }
      }}
    >
      {({ handleSubmit, setFieldValue, values }) => {
        return (
          <Form onSubmit={handleSubmit} className="py-5 px-4">
            <div className="flex flex-col gap-2">
              <div className="text-xl font-semibold"> Team Members</div>

              <div className="flex flex-row gap-4">
                <div className="flex flex-row gap-2 items-center py-2 px-6 font-semibold bg-white w-fit h-fit hadow-md shadow-gray-600">
                  <FiFilter /> Filter
                </div>
                <Link
                  to={"/vteams"}
                  className="flex flex-row gap-2 items-center py-1 px-4 font-semibold border-b border-gray-600 w-fit h-fit"
                >
                  <HiOutlineViewGridAdd /> View
                </Link>
              </div>
              {image && (
                <div className="w-40 h-40">
                  <img
                    src={URL.createObjectURL(image)}
                    alt=""
                    className={"w-full h-full object-contain"}
                  />
                </div>
              )}
              <div className="grid grid-cols-2 gap-4 w-full text-sm h-fit">
                <div>
                  <div className="flex flex-col">
                    <label className="py-2 font-semibold">Name</label>
                    <Field
                      name="name"
                      className="py-2 px-2 rounded-xl border border-gray-400"
                      required
                    />
                    <ErrorMessage
                      className="flex flex-row px-4 text-red-500"
                      name="name"
                      component={"div"}
                    />
                  </div>
                </div>
                <div>
                  <div className="flex flex-col">
                    <label className="py-2 font-semibold">Image</label>
                    <input
                      className="rounded-xl border border-gray-400"
                      type="file"
                      name="image"
                      onInput={(e: any) => {
                        setImage(e.target.files[0]);
                        setFieldValue("image", e.target.files[0]);
                      }}
                      required
                    />
                    <ErrorMessage
                      className="flex flex-row px-4 text-red-500"
                      name="image"
                      component={"div"}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="flex flex-col">
                  <label className="py-2 font-semibold">Position</label>
                  <Field
                    name="position"
                    className="py-2 px-2 rounded-xl border border-gray-400"
                    required
                  />
                  <ErrorMessage
                    className="flex flex-row px-4 text-red-500"
                    name="position"
                    component={"div"}
                  />
                </div>
              </div>
              <div>
                <div className="flex flex-col">
                  <label className="py-2 font-semibold">Description</label>
                  <ReactQuill
                    className="overflow-scroll w-full h-44 bg-white border border-gray-600"
                    theme="snow"
                    value={values.description}
                    onChange={(e) => {
                      setFieldValue("description", e);
                    }}
                  />
                </div>
                <div className="flex justify-center items-center py-2 w-full">
                  <input
                    type="submit"
                    className="py-2 px-12 text-white bg-green-800 border h-fit w-fit"
                  />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
export default Teams;
