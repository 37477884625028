import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";

function ViewGallery() {
  const [contactdata, setContactData] = useState<any>([]);

  const getContact = () => {
    try {
      axios
        .get("https://backend.lophoabroad.com/gallery/")
        .then((res) => {
          console.log(res);
          setContactData([...res.data.data]);
        })
        .catch((err) => {
          toast.error("Could not fetch data");
        });
    } catch (error) {
      toast.error("Server error");
    }
  };

  const DeleteData = (id: any) => {
    try {
      axios
        .delete(`https://backend.lophoabroad.com/gallery/${id}`)
        .then((res) => {
          console.log(res);
          getContact();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getContact();
  }, []);

  return (
    <div className="flex flex-col gap-4 px-2 py-2">
      <div className="font-semibold text-md"> View Data</div>

      <div className="overflow-scroll scroll">
        <table className="bg-gray-300 w-full">
          <thead>
            <tr>
              <th align="center" className="px-4 py-3">
                <input type="checkbox" />
              </th>
              <th align="center" className="px-4 py-3">
                {" "}
                S.N
              </th>
              <th align="center" className="px-12 py-3">
                Image Main
              </th>
              <th align="center" className="px-16 py-3">
                Image Sub
              </th>
              <th align="center" className="px-12 py-3">
                {" "}
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {contactdata.map((val: any, i: number) => {
              return (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <td align="center" className="px-2 py-4">
                    <input type="checkbox" />
                  </td>
                  <td align="center" className="px-2 py-4">
                    {i + 1}
                  </td>
                  <td align="center" className="px-6 py-4">
                    <img
                      src={`https://backend.lophoabroad.com/public/${val.imagemain}`}
                      className="h-12 w-12"
                      alt="image"
                    />
                  </td>
                  <td align="center" className="px-6 flex flex-row gap-1 py-4 ">
                    {val.imagesub.map((item: any, index: number) => {
                      return (
                        <img
                          src={`https://backend.lophoabroad.com/public/${item}`}
                          className="h-12 w-12"
                          alt="image"
                        />
                      );
                    })}
                  </td>

                  <td align="center" className="px-2 py-4 text-blue-600">
                    <button
                      className="bg-red-600 text-white px-4 py-2"
                      onClick={() => {
                        DeleteData(val.id);
                      }}
                    >
                      {" "}
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default ViewGallery;
