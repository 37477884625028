import { useState } from "react";
import Add from "../Other/Add";
import View from "../Other/View";

function Logo() {
  const [activePage, setActivePage] = useState("view");
  return (
    <div>
      <div className="flex flex-row gap-5">
        <button
          className="h-10 w-32 bg-white shadow-md shadow-gray-400"
          onClick={() => setActivePage("view")}
        >
          View
        </button>
        <button
          className="h-10 w-32 bg-white shadow-md shadow-gray-400"
          onClick={() => setActivePage("add")}
        >
          Add
        </button>
      </div>
      {activePage === "view" ? <View /> : <Add />}
    </div>
  );
}

export default Logo;
