import { Form, Formik, ErrorMessage } from "formik";
import axios from "axios";
import * as yup from "yup";
import { toast } from "react-hot-toast";

const schema = yup.object().shape({
  imagec: yup.string().required("Énter Colourful Image"),
  imagebw: yup.string().required("Énter Black and White Image"),
});

function Add() {
  return (
    <div className="flex items-center flex-col gap-4">
      <div className="text-2xl mt-5 px-8 font-semibold"> Add Logo here !</div>

      <Formik
        initialValues={{
          imagec: "",
          imagebw: "",
        }}
        validationSchema={schema}
        onSubmit={(values) => {
          console.log(values);
          try {
            const formData = new FormData();
            formData.append("imagec", values.imagec);
            formData.append("imagebw", values.imagebw);
            axios
              .post(`https://backend.lophoabroad.com/logo/`, formData)
              .then((res) => {
                toast.success("Logo sent successfully");
              })
              .catch((err) => {
                toast.error("Logo could not be added");
              });
          } catch (error) {
            console.log(error);
          }
        }}
      >
        {({ handleSubmit, setFieldValue }) => {
          return (
            <Form
              onSubmit={handleSubmit}
              className="py-8 flex items-center flex-col h-fit w-fit gap-6 border shadow-lg shadow-gray-800 px-20 "
            >
              <div>
                <label
                  htmlFor="imagec"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Image Colourful
                </label>
                <input
                  type="file"
                  onChange={(e) => {
                    setFieldValue("imagec", e.target.files![0]);
                  }}
                  name="imagec"
                  required
                />
                <ErrorMessage
                  className="text-red-500 flex flex-row px-4 "
                  name="imagec"
                  component={"div"}
                />
              </div>
              <div>
                <label
                  htmlFor="imagebw"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Image B and W
                </label>
                <input
                  type="file"
                  onChange={(e) => {
                    setFieldValue("imagebw", e.target.files![0]);
                  }}
                  name="imagebw"
                  id="imagebw"
                  required
                />
                <ErrorMessage
                  className="text-red-500 flex flex-row px-4 "
                  name="imgebw"
                  component={"div"}
                />
              </div>

              <input
                type="submit"
                name="submit"
                className="border h-8 w-32 bg-green-700 text-white"
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default Add;
