import { Form, Formik } from "formik";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-hot-toast";

function Footerpop({
  UpdateData,
  closeModel,
  getContact,
}: {
  UpdateData: any;
  closeModel: any;
  getContact: any;
}) {
  console.log(UpdateData);

  return (
    <div>
      <div className="h-full w-full bg-gradient-to-r from-transparent to-green-50 flex  absolute  ">
        <Formik
          initialValues={{
            description: UpdateData[0].description,
            phone: UpdateData[0].phone,
            telephone: UpdateData[0].telephone,
            email: UpdateData[0].email,
            location: UpdateData[0].location,
          }}
          onSubmit={(values, { resetForm }) => {
            console.log(values);
            try {
              const formData = new FormData();
              formData.append("description", values.description);
              formData.append("phone", values.phone);
              formData.append("telephone", values.telephone);
              formData.append("email", values.email);
              formData.append("location", values.location);

              axios
                .patch(
                  `https://backend.lophoabroad.com/footer/${UpdateData[0].id}`,
                  values
                )
                .then((res) => {
                  toast.success("Update successful");
                  resetForm();
                  closeModel(false);
                  getContact();
                })
                .catch((err) => {
                  toast.error("Could not update data");
                });
            } catch (error) {
              toast.error("Server error");
            }
          }}
        >
          {({ handleSubmit, setFieldValue, values }) => {
            return (
              <Form
                onSubmit={handleSubmit}
                className=" flex flex-col gap-5 h-fit w-3/5 px-10 py-4 bg-gray-300"
              >
                <div>
                  <label
                    htmlFor="description"
                    className="block mb-2 text-md font-medium text-gray-900 dark:text-white"
                  >
                    Description
                  </label>
                  <ReactQuill
                    className="h-44 w-full overflow-scroll bg-white border border-gray-600 "
                    theme="snow"
                    value={values.description}
                    onChange={(e) => {
                      setFieldValue("description", e);
                    }}
                  />
                </div>
                <div>
                  <label
                    htmlFor="phone"
                    className=" text-md font-medium text-gray-900 dark:text-white"
                  >
                    Phone
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setFieldValue("phone", e.target.value);
                    }}
                    name="phone"
                    id="phone"
                    className="bg-gray-50 border border-gray-30 text-gray-900 sm:text-sm w-full h-10 "
                  />
                </div>
                <div>
                  <label
                    htmlFor="telephone"
                    className="block mb-2 text-md font-medium text-gray-900 dark:text-white"
                  >
                    Telephone
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setFieldValue("telephone", e.target.value);
                    }}
                    name="telephone"
                    id="telephone"
                    className="bg-gray-50 border w-full h-10 border-gray-300 text-gray-900 sm:text-sm  "
                  />
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-md font-medium text-gray-900 dark:text-white"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    onChange={(e: any) => {
                      setFieldValue("email", e.target.value);
                    }}
                    name="email"
                    id="email"
                    className=" text-gray-900 w-full h-10"
                  />
                </div>
                <div>
                  <label
                    htmlFor="location"
                    className="block mb-2 text-md font-medium text-gray-900 dark:text-white"
                  >
                    Location
                  </label>
                  <input
                    type="text"
                    onChange={(e: any) => {
                      console.log(e.target.value);
                      setFieldValue("location", e.target.value);
                    }}
                    name="location"
                    id="location"
                    className=" text-gray-700 w-full h-10 bg-white"
                  />
                </div>

                <input
                  type="submit"
                  name="submit"
                  className="bg-green-700 shadow-md shadow-gray-500 text-white px-12 py-2  w-full"
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default Footerpop;
