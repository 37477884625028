import { Formik, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import { toast } from "react-hot-toast";
import axios from "axios";
import Viewintroimage from "../Other/viewintroimage";
import { useState } from "react";

const schema = yup.object().shape({});
function IntroImage() {
  const [images, setImages] = useState<File[]>([]);
  return (
    <div className="flex flex-col gap-4 py-4 px-4 ">
      <div className="font-semibold text-2xl text-gray-600">
        Section Intro Image on Loop
      </div>

      <Formik
        initialValues={{
          imagesub: [],
        }}
        validationSchema={schema}
        onSubmit={(values) => {
          console.log(values);

          try {
            const formData = new FormData();
            values.imagesub.map((val, i) => {
              formData.append("imagesub", val);
            });

            axios
              .post("https://backend.lophoabroad.com/introimage/", formData)
              .then((res) => {
                toast.success("Intro image sent successfully");
              })
              .catch((err) => {
                toast.error(" Intro image could not be added");
              });
          } catch (error) {
            toast.error("Server Error");
          }
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => {
          return (
            <div className="grid grid-cols-10 gap-4 w-full">
              <div className="grid col-span-4 ">
                <Form
                  onSubmit={handleSubmit}
                  className="flex flex-col gap-5 h-fit w-full px-6 py-5 bg-white  shadow-md shadow-gray-400"
                >
                  <div className="flex flex-col gap-4">
                    <label className="font-semibold text-sm text-gray-600">
                      <div className="grid grid-cols-2 gap-5">
                        {images &&
                          images.map((img, index) => (
                            <div
                              key={index}
                              className="w-full aspect-square overflow-clip relative"
                            >
                              <img
                                src={URL.createObjectURL(img)}
                                alt="logo"
                                className="w-full h-full object-cover"
                              />
                              {/* <div className="bg-red-500 w-6 h-6 rounded-full absolute top-0 right-0">
                                X
                              </div> */}
                            </div>
                          ))}
                        {/* <div className="w-full aspect-square flex flex-col items-center justify-center border-2 ">
                          +
                        </div> */}
                      </div>
                    </label>
                    <div>
                      <input
                        type="file"
                        alt="imagesub"
                        onInput={(e: any) => {
                          setImages((prev) => [...prev, e.target.files[0]]);
                          setFieldValue("imagesub", [
                            ...values.imagesub,
                            e.target.files[0],
                          ]);
                        }}
                      />
                      <ErrorMessage
                        className="text-red-500 flex flex-row px-4 "
                        name="imagesub"
                        component={"div"}
                      />
                    </div>
                  </div>

                  <div className="flex justify-center w-full">
                    <button
                      type="submit"
                      className="px-6 w-full py-2 bg-green-600 drop-shadow-md text-white"
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              </div>
              <div className="grid col-span-6 ">
                <Viewintroimage />
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}
export default IntroImage;

//initial values ma define garne but not in yup schema
// and in backend ko entity ma
//     {
//     nullable:true
// }
