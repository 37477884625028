import { Formik, Form, ErrorMessage } from "formik";
import axios from "axios";
import { HiViewGridAdd } from "react-icons/hi";
import { Link } from "react-router-dom";
import * as yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-hot-toast";

const schema = yup.object().shape({
  description: yup.string().required("Required"),
  phone: yup.string().required("Required "),
  telephone: yup.string().required("Required"),
  email: yup.string().required(" Required"),
  location: yup.string().required(" Required"),
});

function Footer() {
  return (
    <div className="flex h-full w-full flex-col px-6 py-4 gap-3">
      <div className="text-xl font-semibold text-gray-700">Footer Section</div>
      <Link
        to={"/viewfooter"}
        className="flex flex-row gap-2 items-center text-lg py-2 h-fit w-fit border-b  border-gray-900 font-semibold"
      >
        <button>View</button>
        <span>
          <HiViewGridAdd />
        </span>
      </Link>
      <Formik
        initialValues={{
          description: "",
          phone: "",
          telephone: "",
          email: "",
          location: "",
        }}
        validationSchema={schema}
        onSubmit={(values, { resetForm }) => {
          console.log(values);
          try {
            const formData = new FormData();
            formData.append("description", values.description);
            formData.append("phone", values.phone);
            formData.append("telephonee", values.telephone);
            formData.append("email", values.email);
            formData.append("location", values.location);
            axios
              .post("https://backend.lophoabroad.com/footer/", values)
              .then((res) => {
                toast.success("Footer data sent successfully");
                resetForm();
              })
              .catch((err) => {
                toast.error(" Footer data could not be added");
              });
          } catch (error) {
            toast.error("Server Error");
          }
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => {
          return (
            <div className="   dark:border-gray-700 flex items-center justify-center">
              <Form
                onSubmit={handleSubmit}
                className=" flex flex-col gap-4 w-full h-fit  px-4 py-4"
              >
                <div className="flex flex-col gap-2">
                  <label
                    htmlFor="description"
                    className="text-md font-medium text-gray-900 dark:text-white"
                  >
                    Description
                  </label>
                  <ReactQuill
                    className="h-40 w-full overflow-scroll bg-white border border-gray-600 "
                    theme="snow"
                    value={values.description}
                    onChange={(e) => {
                      setFieldValue("description", e);
                    }}
                  />
                  <ErrorMessage
                    className="text-red-500 flex flex-row px-4 "
                    name="description"
                    component={"div"}
                  />
                </div>
                <div className="grid grid-cols-2 gap-2">
                  <div className="flex flex-col gap-2">
                    <label
                      htmlFor="phone"
                      className="block mb-2 w-32 text-md font-medium text-gray-900 dark:text-white"
                    >
                      Phone
                    </label>
                    <input
                      type="number"
                      onChange={(e) => {
                        setFieldValue("phone", e.target.value);
                      }}
                      name="phone"
                      id="phone"
                      className="bg-gray-50 border border-gray-600 text-gray-900 sm:text-sm  w-full h-10 "
                      required
                    />
                    <ErrorMessage
                      className="text-red-500 flex flex-row px-4 "
                      name="phone"
                      component={"div"}
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label
                      htmlFor="telephone"
                      className="block mb-2 w-32 text-md font-medium text-gray-900 dark:text-white"
                    >
                      Telephone
                    </label>
                    <input
                      type="text"
                      onChange={(e) => {
                        setFieldValue("telephone", e.target.value);
                      }}
                      name="telephone"
                      id="telephone"
                      className="bg-gray-50 border w-full h-10 border-gray-600 text-gray-900 sm:text-sm  "
                      required
                    />
                    <ErrorMessage
                      className="text-red-500 flex flex-row px-4 "
                      name="telephone"
                      component={"div"}
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label
                      htmlFor="email"
                      className="block mb-2 w-32 text-md font-medium text-gray-900 dark:text-white"
                    >
                      Email
                    </label>
                    <input
                      type="text"
                      onChange={(e) => {
                        setFieldValue("email", e.target.value);
                      }}
                      name="email"
                      id="email"
                      className="bg-gray-50 border w-full h-10 border-gray-600 text-gray-900 sm:text-sm "
                      required
                    />
                    <ErrorMessage
                      className="text-red-500 flex flex-row px-4 "
                      name="email"
                      component={"div"}
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <label
                      htmlFor="location"
                      className="block mb-2 w-32 text-md font-medium text-gray-900 dark:text-white"
                    >
                      Location
                    </label>
                    <input
                      type="text"
                      onChange={(e) => {
                        setFieldValue("location", e.target.value);
                      }}
                      name="location"
                      id="location"
                      className=" border w-full h-10 border-gray-600 text-gray-900 sm:text-sm  "
                      required
                    />
                    <ErrorMessage
                      className="text-red-500 flex flex-row px-4 "
                      name="location"
                      component={"div"}
                    />
                  </div>
                </div>
                <div className="flex items-center w-full">
                  <input
                    type="submit"
                    name="submit"
                    className="bg-green-700 text-white px-12 py-2  w-fit h-fit rounded-md"
                  />
                </div>
              </Form>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}

export default Footer;
