import { useState, useEffect } from "react";
import axios from "axios";
import AboutPop from "../Pictures/Popups/AboutPop";
import { toast } from "react-hot-toast";

function ViewAbout() {
  const [openModel, setOpenModel] = useState(false);
  const [contactdata, setContactData] = useState<any>([]);
  const [UpdateData, setUpdateData] = useState<any>([]);
  const getContact = () => {
    try {
      axios
        .get("https://backend.lophoabroad.com/about/")
        .then((res) => {
          console.log(res);
          setContactData([...res.data.data]);
        })
        .catch((err) => {
          toast.error("Could not fetch data");
        });
    } catch (error) {
      toast.error("Server Error");
    }
  };

  const DeleteData = (id: any) => {
    try {
      axios
        .delete(`https://backend.lophoabroad.com/about/${id}`)
        .then((res) => {
          console.log(res);
          getContact();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getContact();
  }, []);

  return (
    <div className="flex flex-col gap-6 mt-10">
      <div className="font-lg text-3xl text-gray-400">
        {" "}
        View Table Data of About
      </div>
      {openModel && (
        <AboutPop
          UpdateData={UpdateData}
          getContact={getContact}
          closeModel={setOpenModel}
        />
      )}
      <div className="overflow-scroll scroll">
        <table className="bg-green-300 shadow-md shadow-green-400 p-5 w-full overflow-scroll">
          <thead>
            <tr>
              <th align="center" className="px-6 py-3">
                <input type="checkbox" />
              </th>
              <th align="center" className="px-8 py-3">
                S.N
              </th>
              <th className="px-12 py-3">Heading</th>
              <th className="px-12 py-3">Image</th>
              <th className="px-12 py-3">Description</th>
              <th className="px-36 py-3">Mission</th>
              <th className="px-36 py-3">Vision</th>
              <th className="px-36 py-3">Ourstory</th>
              <th className="px-12 py-3">Action</th>
            </tr>
          </thead>
          <tbody>
            {contactdata.map((val: any, i: number) => {
              return (
                <tr
                  key={i}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <td align="center" className=" py-3">
                    <input type="checkbox" />
                  </td>
                  <td align="center" className=" py-3">
                    {i + 1}
                  </td>

                  <td align="center" className="px-2 py-3 ">
                    <div className="break-words line-clamp-3 text-xs">
                      {val.Heading}
                    </div>
                  </td>
                  <td align="center">
                    <img
                      src={`https://backend.lophoabroad.com/public/${val.Image}`}
                      className="h-12 w-12"
                      alt="Image"
                    />
                  </td>
                  <td align="center" className="px-2 py-3">
                    <div
                      className="break-words line-clamp-3 text-xs"
                      dangerouslySetInnerHTML={{ __html: val.Description }}
                    />
                  </td>
                  <td align="center" className="px-2 py-3 ">
                    <div
                      className="break-words line-clamp-3 text-xs"
                      dangerouslySetInnerHTML={{ __html: val.Mission }}
                    />
                  </td>

                  <td align="center" className="px-2 py-3 ">
                    <div
                      className="break-words line-clamp-3 text-xs"
                      dangerouslySetInnerHTML={{ __html: val.Vision }}
                    />
                  </td>
                  <td align="center" className="px-2 py-3 ">
                    <div
                      className="break-words line-clamp-3 text-xs"
                      dangerouslySetInnerHTML={{ __html: val.Ourstory }}
                    />
                  </td>

                  <td align="center" className="flex flex-row">
                    <button
                      className="bg-blue-600 mr-1 text-white px-4 py-2"
                      onClick={() => {
                        setOpenModel(true);
                        setUpdateData([val]);
                      }}
                    >
                      {" "}
                      Edit
                    </button>
                    <button
                      className="bg-red-600 text-white px-4 py-2"
                      onClick={() => {
                        DeleteData(val.id);
                      }}
                    >
                      {" "}
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default ViewAbout;
