import React, { useEffect, useState } from "react";
import { AiOutlineTeam } from "react-icons/ai";
import { BiLogoDribbble } from "react-icons/bi";
import { BsBook } from "react-icons/bs";
import { GiAustralia } from "react-icons/gi";
import { MdRoundaboutRight } from "react-icons/md";
import { TfiGallery } from "react-icons/tfi";
import { VscPreview } from "react-icons/vsc";
import { AiTwotoneHome } from "react-icons/ai";
import { AiFillSignal } from "react-icons/ai";
import { TbIcons } from "react-icons/tb";
import { FaIcons } from "react-icons/fa";
import { FaRegImages } from "react-icons/fa";
import { IoMdContacts } from "react-icons/io";
import { TbSocial } from "react-icons/tb";
import { AiOutlineSortAscending } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import logo from "../Pictures/Lopho Consultancy Logo.jpg";

function Sidebar() {
  const [logo, setLogo] = useState();

  useEffect(() => {
    axios
      .get("https://backend.lophoabroad.com/logo/single")
      .then((res) => setLogo(res.data.data.imagebw))
      .catch((err) => console.log(err));
  }, []);
  const location = useLocation();
  return (
    <div className="h-full w-full sticky flex flex-col gap-3 p-2 text-sm bg-green-900">
      <div>
        <img
          src={`https://backend.lophoabroad.com/public/${logo}`}
          alt="logo"
          className="h-28 w-full px-2 content-center object-contain "
        />
      </div>
      <div className="flex gap-2 flex-col overflow-scroll scroll">
        <div>
          <Link
            to="/"
            className={` w-full flex items-center p-3 justify-between px-8 text-white flex-row h-10 ${
              location.pathname === "/"
                ? "bg-green-700"
                : "bg-transparent first-line:hover:h-5 "
            } `}
          >
            {" "}
            Section Logo
            <div>
              {" "}
              <BiLogoDribbble />{" "}
            </div>
          </Link>
        </div>
        <div>
          <Link
            to="/about"
            className={` w-full flex items-center p-3 justify-between px-8 text-white flex-row h-10 ${
              location.pathname === "/about"
                ? "bg-green-700"
                : "bg-transparent first-line:hover:h-5 "
            } `}
          >
            {" "}
            About
            <div>
              {" "}
              <MdRoundaboutRight />{" "}
            </div>
          </Link>
        </div>
        <div>
          <Link
            to="/cources"
            className={` w-full flex items-center p-3 justify-between px-8 text-white flex-row h-10 ${
              location.pathname === "/cources"
                ? "bg-green-700"
                : "bg-transparent first-line:hover:h-5 "
            } `}
          >
            {" "}
            Cources
            <div>
              {" "}
              <BsBook />{" "}
            </div>
          </Link>
        </div>
        <div>
          <Link
            to="/testimonials"
            className={` w-full flex items-center p-3 justify-between px-8 text-white flex-row h-10 ${
              location.pathname === "/testimonials"
                ? "bg-green-700"
                : "bg-transparent first-line:hover:h-5 "
            } `}
          >
            {" "}
            Testimonials
            <div>
              {" "}
              <VscPreview />{" "}
            </div>
          </Link>
        </div>
        <div>
          <Link
            to="/gallery"
            className={` w-full flex items-center p-3 justify-between px-8 text-white flex-row h-10 ${
              location.pathname === "/gallery"
                ? "bg-green-700"
                : "bg-transparent first-line:hover:h-5 "
            } `}
          >
            {" "}
            Gallery
            <div>
              {" "}
              <TfiGallery />{" "}
            </div>
          </Link>
        </div>
        <div>
          <Link
            to="/teams"
            className={` w-full flex items-center p-3 justify-between px-8 text-white flex-row h-10 ${
              location.pathname === "/teams"
                ? "bg-green-700"
                : "bg-transparent first-line:hover:h-5 "
            } `}
          >
            {" "}
            Teams
            <div>
              {" "}
              <AiOutlineTeam />{" "}
            </div>
          </Link>
        </div>
        <div>
          <Link
            to="/branch"
            className={` w-full flex items-center p-3 justify-between px-8 text-white flex-row h-10 ${
              location.pathname === "/branch"
                ? "bg-green-700"
                : "bg-transparent first-line:hover:h-5 "
            } `}
          >
            {" "}
            Branch
            <div>
              {" "}
              <MdRoundaboutRight />{" "}
            </div>
          </Link>
        </div>
        <div>
          <Link
            to="/country"
            className={` w-full flex items-center p-3 justify-between px-8 text-white flex-row h-10 ${
              location.pathname === "/country"
                ? "bg-green-700"
                : "bg-transparent first-line:hover:h-5 "
            } `}
          >
            {" "}
            Country
            <div>
              {" "}
              <GiAustralia />{" "}
            </div>
          </Link>
        </div>
        <div>
          <Link
            to="/homepage"
            className={` w-full flex items-center p-3 justify-between px-8 text-white flex-row h-10 ${
              location.pathname === "/homepage"
                ? "bg-green-700"
                : "bg-transparent first-line:hover:h-5 "
            } `}
          >
            {" "}
            Home Page
            <div>
              {" "}
              <AiTwotoneHome />{" "}
            </div>
          </Link>
        </div>
        <div>
          <Link
            to="/viewchances"
            className={` w-full flex items-center p-3 justify-between px-8 text-white flex-row h-10 ${
              location.pathname === "/viewchances"
                ? "bg-green-700"
                : "bg-transparent first-line:hover:h-5 "
            } `}
          >
            {" "}
            Chances
            <div>
              {" "}
              <AiFillSignal />{" "}
            </div>
          </Link>
        </div>
        <div>
          <Link
            to="/footer"
            className={` w-full flex items-center p-3 justify-between px-8 text-white flex-row h-10 ${
              location.pathname === "/footer"
                ? "bg-green-700"
                : "bg-transparent first-line:hover:h-5 "
            } `}
          >
            {" "}
            Footer
            <div>
              {" "}
              <AiOutlineSortAscending />{" "}
            </div>
          </Link>
        </div>
        <div>
          <Link
            to="/icons"
            className={` w-full flex items-center p-3 justify-between px-8 text-white flex-row h-10 ${
              location.pathname === "/icons"
                ? "bg-green-700"
                : "bg-transparent first-line:hover:h-5 "
            } `}
          >
            {" "}
            Icons
            <div>
              {" "}
              <TbIcons />{" "}
            </div>
          </Link>
        </div>
        <div>
          <Link
            to="/serviceicons"
            className={` w-full flex items-center p-3 justify-between px-8 text-white flex-row h-10 ${
              location.pathname === "/serviceicons"
                ? "bg-green-700"
                : "bg-transparent first-line:hover:h-5 "
            } `}
          >
            {" "}
            Service Icons
            <div>
              {" "}
              <FaIcons />{" "}
            </div>
          </Link>
        </div>
        <div>
          <Link
            to="/introimage"
            className={` w-full flex items-center p-3 justify-between px-8 text-white flex-row h-10 ${
              location.pathname === "/introimage"
                ? "bg-green-700"
                : "bg-transparent first-line:hover:h-5 "
            } `}
          >
            {" "}
            Intro Image
            <div>
              {" "}
              <FaRegImages />{" "}
            </div>
          </Link>
        </div>
        <div>
          <Link
            to="/listcontact"
            className={` w-full flex items-center p-3 justify-between px-8 text-white flex-row h-10 ${
              location.pathname === "/listcontact"
                ? "bg-green-700"
                : "bg-transparent first-line:hover:h-5 "
            } `}
          >
            {" "}
            List of Contact Us
            <div>
              {" "}
              <IoMdContacts />{" "}
            </div>
          </Link>
        </div>
        <div>
          <Link
            to="/socialmedia"
            className={` w-full flex items-center p-3 justify-between px-8 text-white flex-row h-10 ${
              location.pathname === "/socialmedia"
                ? "bg-green-700"
                : "bg-transparent first-line:hover:h-5 "
            } `}
          >
            {" "}
            Social Media Links
            <div>
              {" "}
              <TbSocial />{" "}
            </div>
          </Link>
        </div>

        {/* <div className="flex flex-row h-10 items-center p-3 hover:bg-green-700 text-white gap-3">
            <Link to={'contact'}>Contact Us</Link>
            <div>
              {' '}
              <IoMdContacts />
            </div>
          </div> */}
      </div>
    </div>
  );
}

export default Sidebar;
