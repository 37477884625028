import { Formik, Form, ErrorMessage } from "formik";
import axios from "axios";
import ViewGallery from "../Other/viewgallery";
import * as yup from "yup";
import { toast } from "react-hot-toast";
import { useState } from "react";

const schema = yup.object().shape({
  imagemain: yup.string().required("Required"),
});

function Gallery() {
  const [main, setMain] = useState<File>();
  const [gallary, setGallary] = useState<File[]>([]);
  return (
    <div className="flex flex-col gap-4 py-4 px-3">
      <div className="text-2xl font-semibold text-gray-700">
        {" "}
        Section Gallery
      </div>
      <Formik
        initialValues={{
          imagemain: "",
          imagesub: [],
        }}
        validationSchema={schema}
        onSubmit={(values, { resetForm }) => {
          try {
            const formData = new FormData();
            formData.append("imagemain", values.imagemain);
            values.imagesub.map((val) => {
              formData.append("imagesub", val);
            });

            axios
              .post("https://backend.lophoabroad.com/gallery/", formData)
              .then((res) => {
                toast.success("Image sent successfully");
                resetForm();
              })
              .catch((err) => {
                toast.error("Image could not be added");
              });
          } catch (error) {
            toast.error("Server error");
          }
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => {
          return (
            <div className="grid grid-cols-12 gap-3 py-2">
              <div className="grid col-span-5 bg-white shadow-lg shadow-gray-700">
                <Form
                  onSubmit={handleSubmit}
                  className="flex flex-col gap-2 py-2 px-6"
                >
                  <p className="font-mono text-md text-gray">
                    {" "}
                    You can upload pictures here!!
                  </p>
                  <div className="flex flex-col gap-2">
                    <label className="font-semibold text-gray-700 text-md">
                      Image Main :
                      {main && (
                        <img
                          src={URL.createObjectURL(main)}
                          alt=""
                          className="object-cover w-40 h-40"
                        />
                      )}
                    </label>
                    <div>
                      <input
                        type="file"
                        alt="imagemain"
                        onChange={(e: any) => {
                          setFieldValue("imagemain", e.target.files[0]);
                          setMain((prev) => e.target.files[0]);
                        }}
                      />
                      <ErrorMessage
                        className="flex flex-row px-4 text-red-500"
                        name="imagemain"
                        component={"div"}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <label className="font-semibold text-gray-700 text-md">
                      Image Sub :
                      <div className="grid grid-cols-2 gap-5 w-full">
                        {gallary.length > 0 &&
                          gallary.map((image, i) => (
                            <img
                              key={i}
                              src={URL.createObjectURL(image)}
                              alt=""
                              className="object-cover w-full aspect-square"
                            />
                          ))}
                      </div>
                    </label>
                    <div>
                      <input
                        type="file"
                        alt="imagesub"
                        onChange={(e: any) => {
                          setGallary((prev) => [...prev, e.target.files[0]]);
                          setFieldValue("imagesub", [
                            ...values.imagesub,
                            e.target.files[0],
                          ]);
                        }}
                      />
                      <ErrorMessage
                        className="flex flex-row px-4 text-red-500"
                        name="imagesub"
                        component={"div"}
                      />
                    </div>
                  </div>

                  <div className="justify-center items-center w-full">
                    <button
                      type="submit"
                      className="py-2 px-6 text-white bg-green-600 w-fit drop-shadow-md"
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              </div>
              <div className="grid col-span-7">
                <ViewGallery />
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}
export default Gallery;

//initial values ma define garne but not in yup schema
// and in backend ko entity ma
//     {
//     nullable:true
// }
