import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Layout from "./Layout/Layout";
import Vcontact from "./Other/Vcontact";
import Viewfooter from "./Other/ViewFooter";
import Vteams from "./Other/Vteams";
import ViewCountry from "./Other/viewcountry";
import ViewGallery from "./Other/viewgallery";
import Viewhomepage from "./Other/viewhomepage";
import About from "./Pages/About";
import Country from "./Pages/Country";
import Cources from "./Pages/Cources";
import Footer from "./Pages/Footer";
import Gallery from "./Pages/Gallery";
import Homepage from "./Pages/Homepage";
import Logo from "./Pages/Logo";
import Teams from "./Pages/Teams";
import Testimonials from "./Pages/Testimonials";
import Viewtestimonials from "./Other/Viewtestimonials";
import ViewAbout from "./Other/ViewAbout";
import IntroImage from "./Pages/IntroImage";
import Viewintroimage from "./Other/viewintroimage";
import Viewchances from "./Other/ViewChances";
import Login from "./Pages/LoginPage";
import Icons from "./Pages/Icons";
import Viewicons from "./Other/ViewIcons";
import Serviceicons from "./Pages/ServiceIcons";
import Viewserviceicons from "./Other/Viewserviceicons";
import ListofContactus from "./Other/ListOfContactUs";
import SocialMedia from "./Pages/SocialMedia";
import Branch from "./Pages/Branch";
import ViewSocialMedia from "./Other/ViewsocialMedia";

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Logo />} />
          <Route path="/about" element={<About />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/cources" element={<Cources />} />
          <Route path="/branch" element={<Branch />} />
          <Route path="/teams" element={<Teams />} />
          <Route path="/vcontact" element={<Vcontact />} />
          <Route path="/vteams" element={<Vteams />} />
          <Route path="/country" element={<Country />} />
          <Route path="/viewcountry" element={<ViewCountry />} />
          <Route path="/viewgallery" element={<ViewGallery />} />
          <Route path="/homepage" element={<Homepage />} />
          <Route path="/viewhomepage" element={<Viewhomepage />} />
          <Route path="/introimage" element={<IntroImage />} />
          <Route path="/viewintroimage" element={<Viewintroimage />} />
          <Route path="/icons" element={<Icons />} />
          <Route path="/footer" element={<Footer />} />
          <Route path="/Viewfooter" element={<Viewfooter />} />
          <Route path="/vtestimonials" element={<Viewtestimonials />} />
          <Route path="/vabout" element={<ViewAbout />} />
          <Route path="/viewchances" element={<Viewchances />} />
          <Route path="/viewicons" element={<Viewicons />} />
          <Route path="/serviceicons" element={<Serviceicons />} />
          <Route path="/viewserviceicons" element={<Viewserviceicons />} />
          <Route path="/listcontact" element={<ListofContactus />} />
          <Route path="/socialmedia" element={<SocialMedia />} />
          <Route path="/viewsocialmedia" element={<ViewSocialMedia />} />
        </Route>

        <Route path="/login" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;
