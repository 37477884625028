import { Form, Formik } from "formik";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-hot-toast";

function Homepagepop({
  UpdateData,
  closeModel,
  getContact,
}: {
  UpdateData: any;
  closeModel: any;
  getContact: any;
}) {
  console.log(UpdateData);

  return (
    <div>
      <div className="h-full w-full bg-gradient-to-r from-transparent to-green-50  flex  absolute  ">
        <Formik
          initialValues={{
            titlemain: UpdateData[0].titlemain,
            maindescription: UpdateData[0].maindescription,
            titlephone: UpdateData[0].titlephone,
            subtitlephone: UpdateData[0].subtitlephone,
            phonedescription: UpdateData[0].phonedescription,
          }}
          onSubmit={(values, { resetForm }) => {
            console.log(values);
            try {
              const formData = new FormData();
              formData.append("titlemain", values.titlemain);
              formData.append("maindescription", values.maindescription);
              formData.append("titlephone", values.titlephone);
              formData.append("subtitlephone", values.subtitlephone);
              formData.append("phonedescription", values.phonedescription);

              axios
                .patch(
                  `https://backend.lophoabroad.com/homepage/${UpdateData[0].id}`,
                  values
                )
                .then((res) => {
                  toast.success("Update successful");
                  resetForm();
                  closeModel(false);
                  getContact();
                })
                .catch((err) => {
                  toast.error("Could not update data");
                });
            } catch (error) {
              toast.error("Server error");
            }
          }}
        >
          {({ handleSubmit, setFieldValue, values }) => {
            return (
              <Form
                onSubmit={handleSubmit}
                className=" flex flex-col gap-3 w-1/2 h-fit px-6 py-4 bg-gray-300 shadow-md shadow-gray-600"
              >
                <div>
                  <label
                    htmlFor="titlemain"
                    className=" text-md font-medium text-gray-900 dark:text-white"
                  >
                    Titlemain
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setFieldValue("titlemain", e.target.value);
                    }}
                    name="titlemain"
                    id="titlemain"
                    className="bg-gray-50 border border-gray-30 text-gray-900 sm:text-sm rounded-lg w-full h-10 "
                  />
                </div>
                <div>
                  <label
                    htmlFor="maindescription"
                    className="block mb-2 text-md font-medium text-gray-900 dark:text-white"
                  >
                    Main-desciption
                  </label>
                  <ReactQuill
                    className="h-32 bg-white w-full overflow-scroll border border-gray-600 "
                    theme="snow"
                    value={values.maindescription}
                    onChange={(e) => {
                      setFieldValue("maindescription", e);
                    }}
                  />
                </div>
                <div>
                  <label
                    htmlFor="titlephone"
                    className="block mb-2 text-md w-full font-medium text-gray-900 dark:text-white"
                  >
                    titlephone
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setFieldValue("titlephone", e.target.value);
                    }}
                    name="titlephone"
                    id="titlephone"
                    className="bg-gray-50 border  border-gray-300 px-4 w-full text-gray-900 sm:text-sm rounded-lg h-10 "
                  />
                </div>
                <div>
                  <label
                    htmlFor="subtitlephone"
                    className="block mb-2 text-md w-full py-2 font-medium text-gray-900 dark:text-white"
                  >
                    subtitlephone
                  </label>
                  <input
                    type="type"
                    onChange={(e: any) => {
                      setFieldValue("subtitlephone", e.target.value);
                    }}
                    name="subtitlephone"
                    id="subtitlephone"
                    className=" text-gray-900 rounded-lg w-full py-3"
                  />
                </div>
                <div>
                  <label
                    htmlFor="phonedescription"
                    className="block mb-2 text-md w-full py-2 font-medium text-gray-900 dark:text-white"
                  >
                    phonedescription
                  </label>
                  <ReactQuill
                    className="h-32 bg-white w-full overflow-scroll border border-gray-600 "
                    theme="snow"
                    value={values.phonedescription}
                    onChange={(e) => {
                      setFieldValue("phonedescription", e);
                    }}
                  />
                </div>

                <input
                  type="submit"
                  name="submit"
                  className="bg-gray-700 shadow-md shadow-gray-500 text-white px-12 py-2  w-full h-8"
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default Homepagepop;
