import { useState, useEffect } from "react";
import axios from "axios";
import Testimonialspop from "../Pictures/Popups/Testimonials";
import { toast } from "react-hot-toast";

function Viewtestimonials() {
  const [openModel, setOpenModel] = useState(false);
  const [contactdata, setContactData] = useState<any>([]);
  const [UpdateData, setUpdateData] = useState<any>([]);
  const getContact = () => {
    try {
      axios
        .get("https://backend.lophoabroad.com/testimonials/")
        .then((res) => {
          console.log(res);
          setContactData([...res.data.data]);
        })
        .catch((err) => {
          toast.error("Could not fetch data");
        });
    } catch (error) {
      toast.error("Server error");
    }
  };

  const DeleteData = (id: any) => {
    try {
      axios
        .delete(`https://backend.lophoabroad.com/testimonials/${id}`)
        .then((res) => {
          console.log(res);
          getContact();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getContact();
  }, []);

  return (
    <div className="flex flex-col gap-6 mt-10">
      <div className="font-lg text-3xl text-gray-400">
        {" "}
        View Table Data of Testimonials
      </div>
      {openModel && (
        <Testimonialspop
          UpdateData={UpdateData}
          getContact={getContact}
          closeModel={setOpenModel}
        />
      )}
      <div className="overflow-scroll scroll">
        <table className="bg-gray-300 p-5 w-full">
          <thead>
            <tr>
              <th align="center" className="px-6 py-3">
                <input type="checkbox" />
              </th>
              <th align="center" className="px-8 py-3">
                S.N
              </th>
              <th className="px-12 py-3">Name</th>
              <th className="px-12 py-3">Image</th>
              <th className="px-12 py-3">Description</th>

              <th className="px-12 py-3">Action</th>
            </tr>
          </thead>
          <tbody>
            {contactdata.map((val: any, i: number) => {
              return (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <td align="center" className=" py-3">
                    <input type="checkbox" />
                  </td>
                  <td align="center" className=" py-3">
                    {i + 1}
                  </td>

                  <td align="center" className="px-2 py-3">
                    {val.Name}
                  </td>
                  <td align="center" className="px-2 py-3">
                    <img
                      src={`https://backend.lophoabroad.com/public/${val.Image}`}
                      className="h-12 w-12"
                    />{" "}
                  </td>
                  <td align="center" className="w-fit py-3 px-4">
                    <div
                      className="break-words line-clamp-3 text-xs"
                      dangerouslySetInnerHTML={{ __html: val.Description }}
                    />
                  </td>

                  <td className="flex flex-row gap-2 items-center justify-center">
                    <button
                      className="bg-blue-600 text-white px-4 py-2"
                      onClick={() => {
                        setOpenModel(true);
                        setUpdateData([val]);
                      }}
                    >
                      {" "}
                      Edit
                    </button>
                    <button
                      className="bg-red-600 text-white px-4 ml-1 py-2"
                      onClick={() => {
                        DeleteData(val.id);
                      }}
                    >
                      {" "}
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default Viewtestimonials;
