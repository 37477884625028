import { useState, useEffect } from "react";
import axios from "axios";

function Vcontact() {
  const [contactdata, setContactData] = useState<any>([]);
  const getContact = () => {
    try {
      axios
        .get("https://backend.lophoabroad.com/contact/")
        .then((res) => {
          console.log(res);
          setContactData([...res.data.data]);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getContact();
  }, []);

  return (
    <div>
      <div className="text-lg font-mono font-semibold"> My Contact Lists</div>

      <table className="bg-gray-300 p-5 w-full">
        <thead>
          <tr>
            <td align="center" className="px-6 py-3">
              <input type="checkbox" />
            </td>
            <td align="center" className="px-8 py-3">
              {" "}
              S.N
            </td>
            <td align="center" className="px-12 py-3">
              Address
            </td>
            <td align="center" className="px-12 py-3">
              Contact
            </td>
            <td align="center" className="px-12 py-3">
              Email
            </td>
            <td align="center" className="px-12 py-3">
              {" "}
              Action
            </td>
          </tr>
        </thead>
        <tbody>
          {contactdata.map((val: any, i: number) => {
            return (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td align="center" className="px-2 py-4 ">
                  <input type="checkbox" />
                </td>
                <td align="center" className="px-2 py-4">
                  {val.id}
                </td>
                <td align="center" className="px-2 py-4">
                  {val.address}
                </td>
                <td align="center" className="px-2 py-4">
                  {val.contact}
                </td>
                <td align="center" className="px-2 py-4">
                  {val.email}
                </td>

                <td className="flex flex-row items-center justify-center mt-4 gap-2 text-blue-600">
                  <button className="bg-red-600 text-white px-4 py-2">
                    {" "}
                    Delete
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Vcontact;
