import  { useState,useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { useLocation,Outlet,useNavigate } from 'react-router-dom';
import Sidebar from '../Navigation/Sidebar';
import Toolbar from '../Navigation/Toolbar';

function Layout() {
  const location = useLocation();
  const navigate = useNavigate();
const [show,setShow]=useState(false)
  useEffect(() => {
    console.log(localStorage.getItem('token'))
    if(localStorage.getItem('token')){
setShow(true)
    }else{
navigate('/login')
    }
  }, [localStorage,location])
  
  return (
    <div className=' h-screen w-screen '>
    {
      show?<div className="grid grid-cols-12  h-screen w-screen relative ">
      <Toaster position="top-right" reverseOrder={false} />
      <div
        className={`col-span-2 overflow-scroll h-screen ${
          location.pathname === '/login' ? 'hidden' : ''
        } top-0 bottom-0 left-0 z-50`}
      >
        <Sidebar />
      </div>
      <div className={`col-span-10 z-50 h-full relative flex flex-col w-full overflow-scroll top-0`}>
        <div className={` sticky top-0 ${location.pathname === '/login' ? 'hidden' : ''}`}>
          <Toolbar />
        </div>

        <div className="w-full h-screen overflow-y-scroll text-sm   flex-1 max-h-full bg-green-50 px-4 py-4">
        <Outlet />
        </div>
      </div>
    </div>: <div>Loading </div>
    }
    </div>
  );
}

export default Layout;
