import { Form, Formik } from "formik";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-hot-toast";

function Testimonialspop({
  UpdateData,
  closeModel,
  getContact,
}: {
  UpdateData: any;
  closeModel: any;
  getContact: any;
}) {
  console.log(UpdateData);

  return (
    <div>
      <div className="w-full overflow-scroll scroll items-center justify-center h-full px-46 bg-gradient-to-r from-transparent to-green-50  flex  absolute  ">
        <Formik
          initialValues={{
            Name: UpdateData[0].Name,
            Image: UpdateData[0].Image,
            Description: UpdateData[0].Description,
          }}
          onSubmit={(values, { resetForm }) => {
            console.log(values);
            try {
              const formData = new FormData();
              formData.append("Name", values.Name);
              formData.append("Image", values.Image);
              formData.append("Description", values.Description);
              axios
                .patch(
                  `https://backend.lophoabroad.com/testimonials/${UpdateData[0].id}`,
                  formData
                )
                .then((res) => {
                  toast.success("Update successful");
                  resetForm();
                  closeModel(false);
                  getContact();
                })
                .catch((err) => {
                  toast.error("Could not update data");
                });
            } catch (error) {
              toast.error("Server error");
            }
          }}
        >
          {({ handleSubmit, setFieldValue, values }) => {
            return (
              <Form
                onSubmit={handleSubmit}
                className=" flex flex-col gap-8 h-fit w-fit  px-14 py-6 bg-gray-200 shadow-lg shadow-gray-600"
              >
                <div>
                  <label
                    htmlFor="Name"
                    className=" text-md font-medium text-gray-900 dark:text-white"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setFieldValue("Name", e.target.value);
                    }}
                    name="Name"
                    id="Name"
                    className="bg-gray-50 border border-gray-500 text-gray-900 sm:text-sm  w-full h-8 "
                  />
                </div>

                <div>
                  <label
                    htmlFor="description"
                    className="block mb-2 text-md font-medium text-gray-900 dark:text-white"
                  >
                    Description
                  </label>
                  <ReactQuill
                    className="h-56 w-96 overflow-scroll bg-white border border-gray-600 "
                    theme="snow"
                    value={values.Description}
                    onChange={(e) => {
                      setFieldValue("Description", e);
                    }}
                  />
                </div>

                <div>
                  <label
                    htmlFor="Image"
                    className="block mb-2 text-md font-medium text-gray-900 dark:text-white"
                  >
                    Image
                  </label>
                  <input
                    type="file"
                    onChange={(e: any) => {
                      console.log(e.target.files[0]);
                      setFieldValue("Image", e.target.files[0]);
                    }}
                    name="Image"
                    id="Image"
                    className=" text-gray-900 rounded-lg "
                  />
                </div>
                <div className="flex items-center justify-center w-full">
                  <input
                    type="submit"
                    name="submit"
                    className="bg-gray-700 shadow-md shadow-gray-500 text-white px-12 py-2 text-left w-fit"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default Testimonialspop;
