import { useEffect, useState } from "react";
import axios from "axios";
import Vcourse from "../Other/Vcourse";
import Acourse from "../Other/Acourse";
import { toast } from "react-hot-toast";

function Cources() {
  const [contactdata, setContactData] = useState<any>([]);

  const getContact = () => {
    try {
      axios
        .get("https://backend.lophoabroad.com/course")
        .then((res) => {
          console.log(res);
          setContactData([...res.data.data]);
        })
        .catch((err) => {
          toast.error("Could not fetch data");
        });
    } catch (error) {
      toast.error("Server error");
    }
  };

  const DeleteData = (id: any) => {
    try {
      axios
        .delete(`https://backend.lophoabroad.com/course/${id}`)
        .then((res) => {
          console.log(res);
          getContact();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getContact();
  }, []);

  const [activePage, setActivePage] = useState("view");
  console.log("active", activePage);

  return (
    <div>
      <div className="flex flex-row gap-8 py-2 px-2">
        <button
          className="h-10 w-32 bg-white shadow-md shadow-gray-400"
          onClick={() => setActivePage("view")}
        >
          View
        </button>
        <button
          className="h-10 w-32 bg-white shadow-md shadow-gray-400"
          onClick={() => setActivePage("add")}
        >
          Add
        </button>
      </div>
      {activePage === "view" ? (
        <Vcourse
          getContact={getContact}
          contactdata={contactdata}
          DeleteData={DeleteData}
        />
      ) : (
        <Acourse getContact={getContact} />
      )}
    </div>
  );
}

export default Cources;
