import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";

function ListofContactus() {
  const [contactdata, setContactData] = useState<any>([]);
  const getContact = () => {
    try {
      axios
        .get("https://backend.lophoabroad.com/listcontact/")
        .then((res) => {
          console.log(res);
          setContactData([...res.data.data]);
        })
        .catch((err) => {
          toast.error("Could not fetch data");
        });
    } catch (error) {
      toast.error("Server error");
    }
  };

  const DeleteData = (id: any) => {
    try {
      axios
        .delete(`https://backend.lophoabroad.com/listcontact/${id}`)
        .then((res) => {
          console.log(res);
          getContact();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getContact();
  }, []);

  return (
    <div className="flex flex-col gap-6 py-4 px-6">
      <div className="text-2xl text-gray-600 font-lg">
        {" "}
        View List of Contact Us
      </div>

      <div className="overflow-scroll scroll">
        <table className="w-full bg-green-200 shadow-md shadow-green-200">
          <thead>
            <tr>
              <th align="center" className="py-3 px-6">
                <input type="checkbox" />
              </th>
              <th align="center" className="py-3 px-8">
                S.N
              </th>
              <th className="px-12 y-3">First Name</th>
              <th className="py-3 px-12">Last Name</th>
              <th className="py-3 px-12">Email</th>
              <th className="py-3 px-12">Mobile Number </th>
              <th className="py-3 px-12">Question regarding</th>
              <th className="py-3 px-12">Study destination</th>
              <th className="py-3 px-12">Message</th>
              <th className="py-3 px-12">Action</th>
            </tr>
          </thead>
          <tbody>
            {contactdata.map((val: any, i: number) => {
              return (
                <tr className="text-xs bg-white border dark:bg-gray-800 dark:border-gray-700">
                  <td align="center" className="py-3">
                    <input type="checkbox" />
                  </td>
                  <td align="center" className="py-3">
                    {i + 1}
                  </td>

                  <td align="center" className="py-3 px-12">
                    {val.firstname}
                  </td>
                  <td align="center" className="py-3 px-12">
                    {val.lastname}
                  </td>
                  <td align="center" className="py-3 px-12 text-xs">
                    {val.email}
                  </td>
                  <td align="center" className="py-3 px-12 text-xs">
                    {val.mobileNumber}
                  </td>
                  <td align="center" className="py-3 px-12 text-xs">
                    {val.question}
                  </td>
                  <td align="center" className="py-3 px-12 text-xs">
                    {val.studydestination}
                  </td>
                  <td align="center" className="py-3 px-12 text-xs">
                    {val.message}
                  </td>

                  <td className="flex flex-row gap-2 justify-center items-center mt-4 text-blue-600">
                    <button
                      className="py-2 px-4 text-white bg-red-600"
                      onClick={() => {
                        DeleteData(val.id);
                      }}
                    >
                      {" "}
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default ListofContactus;
