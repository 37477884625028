import { Formik, Form, ErrorMessage } from "formik";
import axios from "axios";
import * as yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-hot-toast";

const schema = yup.object().shape({
  title: yup.string().required("Énter title"),
  subheading: yup.string().required("Énter subheading"),
  description: yup.string().required("Énter description"),
  image: yup.string().required("Énter image"),
});

function Acourse({ getContact }: { getContact: any }) {
  return (
    <div className="flex items-center justifyy-center py-3">
      <Formik
        initialValues={{
          title: "",
          subheading: "",
          description: "",
          image: "",
          featured: "",
        }}
        validationSchema={schema}
        onSubmit={(values, { resetForm }) => {
          console.log(values);
          try {
            const formData = new FormData();
            formData.append("title", values.title);
            formData.append("subheading", values.subheading);
            formData.append("description", values.description);
            formData.append("image", values.image);
            formData.append("featured", values.featured);
            axios
              .post("https://backend.lophoabroad.com/course/", formData)
              .then((res) => {
                toast.success("Course data sent successfully");
                resetForm();
                getContact();
              })
              .catch((err) => {
                toast.error(" Course data could not be added");
              });
          } catch (error) {
            toast.error("Server Error");
          }
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => {
          return (
            <div className="w-full px-12 py-6  dark:border-gray-700">
              <Form onSubmit={handleSubmit} className=" flex flex-col gap-4">
                <div className="grid grid-cols-2 gap-3">
                  <div>
                    <label
                      htmlFor="title"
                      className="block mb-2 text-md font-medium text-gray-900 dark:text-white"
                    >
                      Title
                    </label>
                    <input
                      type="text"
                      onChange={(e) => {
                        setFieldValue("title", e.target.value);
                      }}
                      name="title"
                      id="title"
                      className="bg-gray-50 h-8 px-2 border border-gray-600 text-gray-900 sm:text-sm  w-full "
                      required
                    />
                    <ErrorMessage
                      className="text-red-500 flex flex-row px-4 "
                      name="title"
                      component={"div"}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="subheading"
                      className="block mb-2 text-md font-medium text-gray-900 dark:text-white"
                    >
                      Sub-Heading
                    </label>
                    <input
                      type="text"
                      onChange={(e) => {
                        setFieldValue("subheading", e.target.value);
                      }}
                      name="subheading"
                      id="subheading"
                      className="bg-gray-50 px-2 border w-full h-8 border-gray-600 text-gray-900 sm:text-sm  "
                      required
                    />
                    <ErrorMessage
                      className="text-red-500 flex flex-row px-4 "
                      name="subheading"
                      component={"div"}
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="description"
                    className="block mb-2 text-md font-medium text-gray-900 dark:text-white"
                  >
                    Description
                  </label>
                  <ReactQuill
                    className="h-44 w-full overflow-scroll bg-white border border-gray-600 "
                    theme="snow"
                    value={values.description}
                    onChange={(e) => {
                      setFieldValue("description", e);
                    }}
                  />
                  <ErrorMessage
                    className="text-red-500 flex flex-row px-4 "
                    name="description"
                    component={"div"}
                  />
                </div>
                <div className="grid grid-cols-2 gap-3">
                  <div>
                    <label
                      htmlFor="image"
                      className="block mb-2 text-md font-medium text-gray-900 dark:text-white"
                    >
                      Image
                    </label>
                    <input
                      type="file"
                      onChange={(e: any) => {
                        console.log(e.target.files[0]);
                        setFieldValue("image", e.target.files[0]);
                      }}
                      name="image"
                      id="image"
                      className="w-full h-fit border border-gray-600 "
                      required
                    />
                    <ErrorMessage
                      className="text-red-500 text-sm flex flex-row px-4 "
                      name="image"
                      component={"div"}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="featured"
                      className="text-md font-medium text-gray-900 py-2"
                    >
                      Featured
                    </label>
                    <select
                      className="w-full h-fit border border-gray-600 py-1"
                      name="featured"
                      id="featured"
                      onChange={(e) =>
                        setFieldValue("featured", e.target.value)
                      }
                    >
                      <option value="select any one">Select any one</option>
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  </div>
                </div>
                <input
                  type="submit"
                  name="submit"
                  className="bg-green-700 text-white px-12 py-2 w-fit"
                />
              </Form>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}

export default Acourse;
