import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";

function ViewSocialMedia() {
  const [contactdata, setContactData] = useState<any>([]);
  const getContact = () => {
    try {
      axios
        .get("https://backend.lophoabroad.com/socialmedia/")
        .then((res) => {
          console.log(res);
          setContactData([...res.data.data]);
        })
        .catch((err) => {
          toast.error("Could not fetch data");
        });
    } catch (error) {
      toast.error("Server error");
    }
  };

  const DeleteData = (id: any) => {
    try {
      axios
        .delete(`https://backend.lophoabroad.com/socialmedia/${id}`)
        .then((res) => {
          console.log(res);
          getContact();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getContact();
  }, []);

  return (
    <div className="flex flex-col gap-3 ">
      <div className="font-lg text-xl text-gray-600">
        {" "}
        View Social Media Icons
      </div>
      <div className="overflow-scroll scroll">
        <table className="bg-green-200 w-full shadow-md shadow-gray-400">
          <thead>
            <tr>
              <th align="center" className="px-6 py-3">
                <input type="checkbox" />
              </th>
              <th align="center" className="px-8 py-3">
                {" "}
                S.N
              </th>
              <th align="center" className="px-12 py-3">
                Title
              </th>
              <th align="center" className="px-12 py-3">
                Link
              </th>
              <th align="center" className="px-12 py-3">
                Logo
              </th>

              <th align="center" className="px-12 py-3">
                {" "}
                Action
              </th>
            </tr>
          </thead>
          <tbody className="shadow-md shadow-gray-500">
            {contactdata.map((val: any, i: number) => {
              return (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <td align="center" className="px-2 py-4">
                    <input type="checkbox" />
                  </td>
                  <td align="center" className="px-2 py-4">
                    {i + 1}
                  </td>

                  <td align="center" className=" py-4 ">
                    {val.title}
                  </td>
                  <td
                    align="center"
                    className=" py-4 max-w-sm break-words line-clamp-1"
                  >
                    {val.link}
                  </td>
                  <td align="center" className="py-4 ">
                    <img
                      src={`https://backend.lophoabroad.com/public/${val.logo}`}
                      className="h-12 w-12"
                      alt="image"
                    />
                  </td>

                  <td align="center" className=" py-4 text-blue-600">
                    <button
                      className="bg-red-600 text-white px-4 py-2"
                      onClick={() => {
                        DeleteData(val.id);
                      }}
                    >
                      {" "}
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default ViewSocialMedia;
