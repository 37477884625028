import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";

function Viewintroimage() {
  const [contactdata, setContactData] = useState<any>([]);
  const getContact = () => {
    try {
      axios
        .get("https://backend.lophoabroad.com/introimage/")
        .then((res) => {
          console.log(res);
          setContactData([...res.data.data]);
        })
        .catch((err) => {
          toast.error("Could not fetch data");
        });
    } catch (error) {
      toast.error("Server error");
    }
  };

  const DeleteData = (id: any) => {
    try {
      axios
        .delete(`https://backend.lophoabroad.com/introimage/${id}`)
        .then((res) => {
          console.log(res);
          getContact();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getContact();
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <div className="text-xl font-semibold text-gray-400">
        {" "}
        View Table Data of Image Loop
      </div>
      <table className="w-full bg-green-200">
        <thead>
          <tr>
            <th align="center" className="py-3 px-6">
              <input type="checkbox" />
            </th>
            <th align="center" className="py-3 px-8">
              {" "}
              S.N
            </th>

            <th align="center" className="py-3 px-12">
              Images on Loop
            </th>
            <th align="center" className="py-3 px-12">
              {" "}
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {contactdata.map((val: any, i: number) => {
            return (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td align="center" className="py-4 px-2">
                  <input type="checkbox" />
                </td>
                <td align="center" className="py-4 px-2">
                  {i + 1}
                </td>

                <td align="center" className="flex flex-row gap-1 py-4 px-2">
                  {val.imagesub.map((item: any, index: number) => {
                    return (
                      <img
                        src={`https://backend.lophoabroad.com/public/${item}`}
                        alt={""}
                        className="w-12 h-12"
                      />
                    );
                  })}
                </td>

                <td align="center" className="py-4 px-2 text-blue-600">
                  <button
                    className="py-2 px-4 text-white bg-red-600"
                    onClick={() => {
                      DeleteData(val.id);
                    }}
                  >
                    {" "}
                    Delete
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
export default Viewintroimage;
