import { Form, Formik } from "formik";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-hot-toast";

function Teamspop({
  UpdateData,
  closeModel,
  getContact,
}: {
  UpdateData: any;
  closeModel: any;
  getContact: any;
}) {
  console.log(UpdateData);

  return (
    <div>
      <div className="h-full w-full bg-gradient-to-r from-transparent to-green-50  justify-center  flex  absolute  ">
        <Formik
          initialValues={{
            name: UpdateData[0].name,
            image: UpdateData[0].image,
            position: UpdateData[0].position,
            description: UpdateData[0].description,
          }}
          onSubmit={(values, { resetForm }) => {
            console.log(values);
            try {
              const formData = new FormData();
              formData.append("name", values.name);
              formData.append("image", values.image);
              formData.append("position", values.position);
              formData.append("description", values.description);
              axios
                .patch(
                  `https://backend.lophoabroad.com/teams/${UpdateData[0].id}`,
                  values
                )
                .then((res) => {
                  toast.success("Update successful");
                  resetForm();
                  closeModel(false);
                  getContact();
                })
                .catch((err) => {
                  toast.error("Could not update data");
                });
            } catch (error) {
              toast.error("Server error");
            }
          }}
        >
          {({ handleSubmit, setFieldValue, values }) => {
            return (
              <Form
                onSubmit={handleSubmit}
                className=" flex flex-col gap-3 h-fit w-1/2 px-10 py-4 bg-gray-300"
              >
                <div>
                  <label
                    htmlFor="name"
                    className=" text-md font-medium text-gray-900 dark:text-white"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setFieldValue("name", e.target.value);
                    }}
                    name="name"
                    id="name"
                    className="bg-gray-50 border border-gray-30 text-gray-900 sm:text-sm  w-full h-10 "
                  />
                </div>
                <div>
                  <label
                    htmlFor="image"
                    className="block mb-2 text-md font-medium text-gray-900 dark:text-white"
                  >
                    Image
                  </label>
                  <input
                    type="file"
                    onChange={(e: any) => {
                      console.log(e.target.files[0]);
                      setFieldValue("image", e.target.files[0]);
                    }}
                    name="image"
                    id="image"
                    className=" text-gray-900"
                  />
                </div>
                <div>
                  <label
                    htmlFor="position"
                    className="block mb-2 text-md font-medium text-gray-900 dark:text-white"
                  >
                    Position
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setFieldValue("position", e.target.value);
                    }}
                    name="position"
                    id="position"
                    className="bg-gray-50 border w-full h-10 border-gray-300 text-gray-900 sm:text-sm"
                  />
                </div>
                <div>
                  <label
                    htmlFor="description"
                    className="block mb-2 text-md font-medium text-gray-900 dark:text-white"
                  >
                    Description
                  </label>
                  <ReactQuill
                    className="h-32 w-full overflow-scroll  border border-gray-600 "
                    theme="snow"
                    value={values.description}
                    onChange={(e) => {
                      setFieldValue("description", e);
                    }}
                  />
                </div>

                <input
                  type="submit"
                  name="submit"
                  className="bg-green-700 shadow-md shadow-gray-500 text-white px-12 py-2 w-full"
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default Teamspop;
