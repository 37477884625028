import { useState, useEffect } from "react";
import axios from "axios";
import Teamspop from "../Pictures/Popups/Teamspop";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";

function Vteams() {
  const [openModel, setOpenModel] = useState(false);
  const [contactdata, setContactData] = useState<any>([]);
  const [UpdateData, setUpdateData] = useState<any>([]);

  const getContact = () => {
    try {
      axios
        .get("https://backend.lophoabroad.com/teams/")
        .then((res) => {
          console.log(res);
          setContactData([...res.data.data]);
        })
        .catch((err) => {
          toast.error("Could not fetch data");
        });
    } catch (error) {
      toast.error("Server error");
    }
  };

  const DeleteData = (id: any) => {
    try {
      axios
        .delete(`https://backend.lophoabroad.com/teams/${id}`)
        .then((res) => {
          getContact();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getContact();
  }, []);

  return (
    <div className="flex flex-col gap-6 py-6 px-4">
      <div className="text-2xl text-gray-400 font-lg"> View Table Teams</div>
      {openModel && (
        <Teamspop
          UpdateData={UpdateData}
          getContact={getContact}
          closeModel={setOpenModel}
        />
      )}

      <Link
        to={"/teams"}
        className="flex flex-row gap-2 items-center py-1 px-4 font-semibold border-b border-gray-600 w-fit h-fit"
      >
        Add
      </Link>
      <table className="p-5 w-full bg-gray-300">
        <thead>
          <tr>
            <th align="center" className="py-3 px-6">
              <input type="checkbox" />
            </th>
            <th align="center" className="py-3 px-12">
              S.N
            </th>
            <th align="center" className="py-3 px-12">
              {" "}
              Name
            </th>
            <th align="center" className="py-3 px-12">
              Image
            </th>
            <th align="center" className="py-3 px-12">
              Position
            </th>
            <th align="center" className="py-3 px-12">
              Description
            </th>
            <th align="center" className="py-3 px-12">
              {" "}
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {contactdata.map((val: any, i: number) => {
            return (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td align="center" className="py-4 px-2">
                  <input type="checkbox" />
                </td>
                <td align="center" className="py-4 px-2">
                  {i + 1}
                </td>
                <td align="center" className="py-4 px-2">
                  {val.name}
                </td>
                <td align="center" className="py-4 px-2">
                  <img
                    src={`https://backend.lophoabroad.com/public/${val.image}`}
                    className="w-12 h-12"
                    alt=""
                  />
                </td>
                <td align="center" className="py-4 px-2">
                  {val.position}
                </td>
                <td align="center" className="py-4 px-2">
                  <div
                    className="text-xs break-words line-clamp-3"
                    dangerouslySetInnerHTML={{ __html: val.Description }}
                  />
                </td>
                <td className="flex flex-row gap-2 justify-center items-center mt-4 text-blue-600">
                  <button
                    className="py-2 px-4 text-white bg-blue-600"
                    onClick={() => {
                      setOpenModel(true);
                      setUpdateData([val]);
                    }}
                  >
                    Edit
                  </button>

                  <button
                    className="py-2 px-4 text-white bg-red-600"
                    onClick={() => {
                      DeleteData(val.id);
                    }}
                  >
                    {" "}
                    Delete
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
export default Vteams;
