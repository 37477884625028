import { Field, Form, Formik } from "formik";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-hot-toast";

function AboutPop({
  UpdateData,
  closeModel,
  getContact,
}: {
  UpdateData: any;
  closeModel: any;
  getContact: any;
}) {
  console.log(UpdateData);

  return (
    <div>
      <div className="h-full w-full bg-gradient-to-r from-transparent to-green-50  justify-center  flex  absolute  ">
        <Formik
          initialValues={{
            Mission: UpdateData[0].Mission,
            Vision: UpdateData[0].Vision,
            Ourstory: UpdateData[0].Ourstory,
            Heading: UpdateData[0].Heading,
            Description: UpdateData[0].Description,
            Image: UpdateData[0].Image,
          }}
          onSubmit={(values, { resetForm }) => {
            console.log(values);
            try {
              const formData = new FormData();
              formData.append("Image", values.Image);
              formData.append("Mission", values.Mission);
              formData.append("Vision", values.Vision);
              formData.append("Ourstory", values.Ourstory);
              formData.append("Heading", values.Heading);
              formData.append("Description", values.Description);
              axios
                .patch(
                  `https://backend.lophoabroad.com/about/${UpdateData[0].id}`,
                  formData
                )
                .then((res) => {
                  toast.success("Update successful");
                  resetForm();
                  closeModel(false);
                  getContact();
                })
                .catch((err) => {
                  toast.error("Could not update data");
                });
            } catch (error) {
              toast.error("Server error");
            }
          }}
        >
          {({ handleSubmit, setFieldValue, values }) => {
            return (
              <div className="h-full w-full">
                <Form
                  onSubmit={handleSubmit}
                  className=" relative flex flex-col gap-5 h-fit w-1/2 px-8 py-4 bg-gray-300 shadow-md shadow-gray-600"
                >
                  <div>
                    <label className="block mb-2 text-md font-medium text-gray-900 dark:text-white">
                      Heading
                    </label>
                    <Field
                      type="text"
                      name="Heading"
                      id="Heading"
                      className="bg-gray-50 border w-full h-8 border-gray-300 text-gray-900 sm:text-sm  "
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="Image"
                      className="block mb-2 text-md font-medium text-gray-900 dark:text-white"
                    >
                      Image
                    </label>
                    <input
                      type="file"
                      onChange={(e: any) => {
                        setFieldValue("Image", e.target.files[0]);
                      }}
                      name="Image"
                      id="Image"
                      className=" text-gray-900 rounded-lg "
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="Description"
                      className="block mb-2 text-md font-medium text-gray-900 dark:text-white"
                    >
                      Description
                    </label>

                    <ReactQuill
                      className="h-32 bg-white w-full overflow-scroll border border-gray-600 "
                      theme="snow"
                      value={values.Description}
                      onChange={(e) => {
                        setFieldValue("Description", e);
                      }}
                    />
                  </div>

                  <div>
                    <label className=" text-md font-medium text-gray-900 dark:text-white">
                      Mission
                    </label>

                    <ReactQuill
                      className="h-32 w-full overflow-scroll  border border-gray-600 "
                      theme="snow"
                      value={values.Mission}
                      onChange={(e) => {
                        setFieldValue("Mission", e);
                      }}
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-md font-medium text-gray-900 dark:text-white">
                      Vision
                    </label>

                    <ReactQuill
                      className="h-32 w-full overflow-scroll  border border-gray-600 "
                      theme="snow"
                      value={values.Vision}
                      onChange={(e) => {
                        setFieldValue("Vision", e);
                      }}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="Ourstory"
                      className="block mb-2 text-md font-medium text-gray-900 dark:text-white"
                    >
                      Ourstory
                    </label>
                    <ReactQuill
                      className="h-32 w-full overflow-scroll border border-gray-600 "
                      theme="snow"
                      value={values.Ourstory}
                      onChange={(e) => {
                        setFieldValue("Ourstory", e);
                      }}
                    />
                  </div>
                  <div className="flex items-center w-full">
                    <input
                      type="submit"
                      name="submit"
                      className="bg-gray-700 shadow-md shadow-gray-500 text-white px-12 py-2 text-left w-fit"
                    />
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default AboutPop;
