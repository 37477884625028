import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";

function Viewchances() {
  const [contactdata, setContactData] = useState<any>([]);

  const getContact = () => {
    try {
      axios
        .get("https://backend.lophoabroad.com/chance/")
        .then((res) => {
          console.log(res);
          setContactData([...res.data.data]);
        })
        .catch((err) => {
          toast.error("Could not fetch data");
        });
    } catch (error) {
      toast.error("Server error");
    }
  };

  useEffect(() => {
    getContact();
  }, []);

  return (
    <div className="flex flex-col gap-6 mt-10">
      <div className="font-lg text-3xl text-gray-400">
        {" "}
        View Table Data of Chances
      </div>
      <div className="scroll overflow-scroll">
        <table className="bg-gray-300 p-5 w-full overflow-scroll">
          <thead>
            <tr>
              <td align="center" className="px-6 py-3">
                <input type="checkbox" />
              </td>
              <td align="center" className="px-8 py-3">
                S.N
              </td>
              <td className="px-12 py-3">Name</td>
              <td className="px-12 py-3">Email</td>
              <td className="px-12 py-3">Phone</td>
              <td className="px-12 py-3">HighestStudy</td>
              <td className="px-12 py-3">MarksInBachelor</td>
              <td className="px-12 py-3">MarksInPlus2</td>
              <td className="px-12 py-3">MarksInSLC</td>
              <td className="px-12 py-3">Country</td>
              <td className="px-12 py-3">YourStream</td>
              <td className="px-12 py-3">FamilyIncome</td>
              <td className="px-12 py-3">SubjectOfChoice</td>
              <td className="px-12 py-3">ProficiencyTest</td>
              <td className="px-12 py-3">Marks_ielts</td>
              <td className="px-12 py-3">Listening_ielts</td>
              <td className="px-12 py-3">Reading_ielts</td>
              <td className="px-12 py-3">Writing_ielts</td>
              <td className="px-12 py-3">Speaking_ielts</td>
              <td className="px-12 py-3">Marks_pte</td>
              <td className="px-12 py-3">Listening_pte</td>
              <td className="px-12 py-3">Reading_pte</td>
              <td className="px-12 py-3">Writing_pte</td>
              <td className="px-12 py-3">Speaking_pte</td>
              <td className="px-12 py-3">DepedentVisa</td>
            </tr>
          </thead>
          <tbody>
            {contactdata.map((val: any, i: number) => {
              return (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <td align="center" className=" py-3">
                    <input type="checkbox" />
                  </td>
                  <td align="center" className=" py-3">
                    {i + 1}
                  </td>

                  <td align="center" className="px-2 py-3">
                    {val.name}
                  </td>
                  <td align="center" className="px-2 py-3">
                    {val.email}
                  </td>
                  <td align="center" className=" text-sm">
                    {val.phone}
                  </td>
                  <td align="center" className=" text-sm">
                    {val.highestStudy}
                  </td>
                  <td align="center" className=" text-sm">
                    {val.marksInBachelor}
                  </td>
                  <td align="center">{val.marksInPlus2}</td>
                  <td align="center">{val.marksInSLC}</td>
                  <td align="center">{val.countryOfChoice}</td>
                  <td align="center">{val.yourStream}</td>
                  <td align="center">{val.familyIncome}</td>
                  <td align="center">{val.subjectOfChoice}</td>
                  <td align="center">{val.proficiencyTest}</td>
                  <td align="center">{val.marks_ielts}</td>
                  <td align="center">{val.listening_ielts}</td>
                  <td align="center">{val.reading_ielts}</td>
                  <td align="center">{val.writing_ielts}</td>
                  <td align="center">{val.speaking_ielts}</td>

                  <td align="center">{val.marks_pte}</td>
                  <td align="center">{val.listening_pte}</td>
                  <td align="center">{val.reading_pte}</td>
                  <td align="center">{val.writing_pte}</td>
                  <td align="center">{val.speaking_pte}</td>
                  <td align="center">{val.depedentVisa}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default Viewchances;
