import { Form, Formik, ErrorMessage, Field } from "formik";
import { toast } from "react-hot-toast";
import { FiFilter } from "react-icons/fi";
import * as Yup from "yup";
import axios from "../Navigation/axios";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const schema = Yup.object().shape({
  Mission: Yup.string().required("Required"),
  Vision: Yup.string().required("Required"),
  Ourstory: Yup.string().required("Required"),
  Heading: Yup.string().required("Required"),
  Description: Yup.string().required("Required"),
});

function About() {
  return (
    <div>
      <Formik
        initialValues={{
          Mission: "",
          Vision: "",
          Ourstory: "",
          Heading: "",
          Description: "",
          Image: "",
        }}
        validationSchema={schema}
        onSubmit={(values, { resetForm }) => {
          console.log(values);
          try {
            const formData = new FormData();
            formData.append("Mission", values.Mission);
            formData.append("Vision", values.Vision);
            formData.append("Ourstory", values.Ourstory);
            formData.append("Heading", values.Heading);
            formData.append("Description", values.Description);
            formData.append("Image", values.Image);
            axios
              .post(`https://backend.lophoabroad.com/about/`, formData)
              .then((res) => {
                toast.success("About us data sent successfully");
                resetForm();
              })
              .catch((err) => {
                toast.error(" About us data could not be added");
              });
          } catch (error) {
            toast.error("Server Error");
          }
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => {
          return (
            <div className="flex gap-5 flex-col px-5 py-3">
              <div className="text-lg font-semibold"> About Us</div>
              <div className="flex flex-row gap-3  text-lg">
                <div className="flex flex-row gap-2 items-center ">
                  <FiFilter /> Filter
                </div>
                <Link
                  to={"/vabout"}
                  className="flex flex-row  px-4 py-1  border-b border-gray-800 gap-2 items-center"
                >
                  <FiFilter /> View
                </Link>
              </div>
              <div className="overflow-scroll scroll">
                <Form onSubmit={handleSubmit} className="flex flex-col gap-5 ">
                  <div className="grid grid-cols-2 gap-3">
                    <div className="flex flex-row gap-2 ">
                      <label className="font-semibold ">Heading</label>
                      <Field
                        name="Heading"
                        className="h-8 px-3 w-full border border-gray-600 bg-white shadow-sm shadow-gray-300"
                        required
                      />
                    </div>
                    <div className="flex flex-row gap-2 ">
                      <label className="font-semibold "> Image</label>
                      <input
                        className="border border-gray-600 w-full"
                        type="file"
                        onChange={(e: any) => {
                          setFieldValue("Image", e.target.files[0]);
                        }}
                        name="Image"
                        required
                      />
                      <ErrorMessage
                        className="text-red-500 flex flex-row px-4 "
                        name="Image"
                        component={"div"}
                      />
                    </div>
                  </div>

                  <div className="flex flex-col gap-2 ">
                    <label className="font-semibold  w-40"> Description</label>
                    <ReactQuill
                      className="h-44 w-full overflow-scroll  bg-white border border-gray-600 "
                      theme="snow"
                      value={values.Description}
                      onChange={(e) => {
                        setFieldValue("Description", e);
                      }}
                    />
                    <ErrorMessage
                      className="text-red-500 flex flex-row px-4 "
                      name="Description"
                      component={"div"}
                    />
                  </div>
                  <div className="flex flex-col gap-2  ">
                    <label className="font-semibold  w-40"> Our Mission</label>
                    <ReactQuill
                      className="h-44 w-full overflow-scroll bg-white  border border-gray-600 "
                      theme="snow"
                      value={values.Mission}
                      onChange={(e) => {
                        setFieldValue("Mission", e);
                      }}
                    />
                    <ErrorMessage
                      className="text-red-500 flex flex-row px-4 "
                      name="Mission"
                      component={"div"}
                    />
                  </div>
                  <div className="flex flex-col gap-2 ">
                    <label className="font-semibold w-40"> Our Vision</label>
                    <ReactQuill
                      className="h-44 w-full overflow-scroll bg-white border border-gray-600 "
                      theme="snow"
                      value={values.Vision}
                      onChange={(e) => {
                        setFieldValue("Vision", e);
                      }}
                    />
                    <ErrorMessage
                      className="text-red-500 flex flex-row px-4 "
                      name="Vision"
                      component={"div"}
                    />
                  </div>
                  <div className="flex flex-col gap-2 ">
                    <label className="font-semibold   w-40"> Our Story</label>

                    <ReactQuill
                      className="h-44 w-full overflow-scroll bg-white border border-gray-600 "
                      theme="snow"
                      value={values.Ourstory}
                      onChange={(e) => {
                        setFieldValue("Ourstory", e);
                      }}
                    />
                    <ErrorMessage
                      className="text-red-500 flex flex-row px-4 "
                      name="Ourstory"
                      component={"div"}
                    />
                  </div>
                  <div className="w-full flex items-center justify-center">
                    <input
                      type="submit"
                      name="submit"
                      className=" py-2 w-fit h-fit px-12 bg-green-600 text-white text-md"
                    />
                  </div>
                </Form>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}

export default About;
