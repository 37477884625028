import { Formik, Form, ErrorMessage } from "formik";
import axios from "axios";
import * as yup from "yup";
import Viewserviceicons from "../Other/Viewserviceicons";
import { toast } from "react-hot-toast";
import { useState } from "react";

const schema = yup.object().shape({
  title: yup.string().required("Required"),
  image: yup.string().required("Required"),
});

function Serviceicons() {
  const [icon, setIcon] = useState<File>();
  return (
    <div className="flex flex-col gap-2 px-4 py-4">
      <div className=" text-2xl text-gray-400">Add Service Icons</div>

      <Formik
        initialValues={{
          title: "",
          image: "",
        }}
        validationSchema={schema}
        onSubmit={(values) => {
          console.log(values);

          try {
            const formData = new FormData();
            formData.append("title", values.title);
            formData.append("image", values.image);

            axios
              .post("https://backend.lophoabroad.com/serviceicons/", formData)
              .then((res) => {
                toast.success("Service Icons sent successfully");
              })
              .catch((err) => {
                toast.error(" Service Icons could not be added");
              });
          } catch (error) {
            toast.error("Server Error");
          }
        }}
      >
        {({ handleSubmit, setFieldValue }) => {
          return (
            <div className="flex items-center justify-center h-full w-full overflow-scroll scroll">
              <Form
                onSubmit={handleSubmit}
                className="flex  h-fit flex-col justify-center  bg-green-100 px-12 py-6 w-1/2 gap-4 shadow-md shadow-gray-600"
              >
                <div className="flex flex-row gap-3 items-center">
                  <label className="text-md w-24">Title</label>
                  <input
                    className=" px-2 py-1 w-full border-2 border-gray-400"
                    type="text"
                    onChange={(e: any) => {
                      setFieldValue("title", e.target.value);
                    }}
                  />
                  <ErrorMessage
                    className="text-red-500 flex flex-row px-4 "
                    name="title"
                    component={"div"}
                  />
                </div>
                <div className="flex flex-row gap-4 items-center">
                  <label className=" w-24 "> Image</label>
                  <input
                    type="file"
                    onChange={(e: any) => {
                      setIcon(e.target.files[0]);
                      setFieldValue("image", e.target.files[0]);
                    }}
                  />
                  <ErrorMessage
                    className="text-red-500 flex flex-row px-4 "
                    name="image"
                    component={"div"}
                  />
                </div>
                {icon && (
                  <div className="w-40">
                    <img src={URL.createObjectURL(icon)} alt="" />
                  </div>
                )}
                <div>
                  <button
                    type="submit"
                    className="h-8 w-full  bg-green-600 drop-shadow-md text-white"
                  >
                    Submit
                  </button>
                </div>{" "}
              </Form>
            </div>
          );
        }}
      </Formik>
      <Viewserviceicons />
    </div>
  );
}
export default Serviceicons;
