import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import * as yup from "yup";
import ViewSocialMedia from "../Other/ViewsocialMedia";
import { toast } from "react-hot-toast";

const schema = yup.object().shape({
  link: yup.string().required("Required"),
  title: yup.string().required("Required"),
  logo: yup.string().required("Required"),
});

function SocialMedia() {
  const [socialMediaLogo, setSocialMediaLogo] = useState<File>();
  return (
    <div className="grid grid-cols-12 gap-6 py-8 px-6 bg-red-100 overflow-clip">
      <div className={`col-span-4`}>
        <div className=" text-xl text-gray-700">Add Social Media Links </div>
        <div className={`bg-red-50 w-full`}>
          <Formik
            initialValues={{
              link: "",
              title: "",
              logo: "",
            }}
            validationSchema={schema}
            onSubmit={(values, { resetForm }) => {
              try {
                const formData = new FormData();
                formData.append("link", values.link);
                formData.append("title", values.title);
                formData.append("logo", values.logo);

                axios
                  .post(
                    "https://backend.lophoabroad.com/socialmedia/",
                    formData
                  )
                  .then((res) => {
                    toast.success("Social Media data sent successfully");
                    resetForm();
                  })
                  .catch((err) => {
                    toast.error(" Social Media data could not be added");
                  });
              } catch (error) {
                toast.error("Server Error");
              }
            }}
          >
            {({ handleSubmit, setFieldValue }) => {
              return (
                <div className=" gap-3">
                  <div className="grid col-span-4 h-full w-full">
                    <Form
                      onSubmit={handleSubmit}
                      className="flex  h-fit flex-col justify-center  bg-green-100 px-6 py-6 w-full gap-6 shadow-md shadow-gray-600"
                    >
                      <div className="flex flex-row gap-2 ">
                        <label htmlFor="title">Title</label>
                        <Field
                          className=" px-2 py-1 w-full border border-gray-600"
                          type="text"
                          name="title"
                        />
                        <ErrorMessage
                          className="text-red-500 flex flex-row px-4 "
                          name="title"
                          component={"div"}
                        />
                      </div>
                      <div className="flex flex-row  gap-2">
                        <label htmlFor="link">Link</label>
                        <Field
                          className=" px-2 py-1 w-full border border-gray-600"
                          type="text"
                          name="link"
                        />
                        <ErrorMessage
                          className="text-red-500 flex flex-row px-4 "
                          name="link"
                          component={"div"}
                        />
                      </div>
                      <div className="flex flex-row gap-2 ">
                        <label htmlFor="logo" className="flex flex-col gap-2">
                          Logo
                          <span className="text-xs w-32 h-fit text-gray-700">
                            Prefer B&W logo only
                          </span>
                        </label>
                        <input
                          className=" px-2 py-1 w-full"
                          type="file"
                          onChange={(e: any) => {
                            setSocialMediaLogo(e.target.files[0]);
                            setFieldValue("logo", e.target.files[0]);
                          }}
                          name="logo"
                          required
                        />
                        <ErrorMessage
                          className="text-red-500 flex flex-row px-4 "
                          name="logo"
                          component={"div"}
                        />
                      </div>
                      {socialMediaLogo && (
                        <div className={"w-40 h-40"}>
                          <img
                            src={URL.createObjectURL(socialMediaLogo)}
                            className="w-full h-full object-cover"
                          />
                        </div>
                      )}
                      <div>
                        <button
                          type="submit"
                          className="h-8 w-full  bg-green-600 drop-shadow-md text-white"
                        >
                          Submit
                        </button>
                      </div>{" "}
                    </Form>
                  </div>
                  <div className="grid grid-col-6 w-full"></div>
                </div>
              );
            }}
          </Formik>
        </div>
      </div>
      <div className={`col-span-8`}>
        <ViewSocialMedia />
      </div>
    </div>
  );
}
export default SocialMedia;
