import { useState, useEffect } from "react";
import axios from "axios";
import Homepagepop from "../Pictures/Popups/HomepagePop";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
function Viewhomepage() {
  const [openModel, setOpenModel] = useState(false);
  const [contactdata, setContactData] = useState<any>([]);
  const [UpdateData, setUpdateData] = useState<any>([]);

  const getContact = () => {
    try {
      axios
        .get("https://backend.lophoabroad.com/homepage/")
        .then((res) => {
          console.log(res);
          setContactData([...res.data.data]);
        })
        .catch((err) => {
          toast.error("Could not fetch data");
        });
    } catch (error) {
      toast.error("Server error");
    }
  };

  const DeleteData = (id: any) => {
    try {
      axios
        .delete(`https://backend.lophoabroad.com/homepage/${id}`)
        .then((res) => {
          console.log(res);
          getContact();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getContact();
  }, []);

  return (
    <div>
      <div className="text-lg font-mono font-semibold"> My Home Page Lists</div>
      {openModel && (
        <Homepagepop
          UpdateData={UpdateData}
          getContact={getContact}
          closeModel={setOpenModel}
        />
      )}
      <Link
        to={"/homepage"}
        className="flex flex-row gap-2 items-center h-fit w-fit px-6 py-2 border-b border-gray-800 text-lg"
      >
        <button>Add</button>
      </Link>
      <div className="overflow-scroll scroll">
        <table className="bg-gray-300 p-5 w-full overflow-scroll">
          <thead>
            <tr>
              <th align="center" className="px-6 py-3">
                <input type="checkbox" />
              </th>
              <th align="center" className="px-8 py-3">
                {" "}
                S.N
              </th>
              <th align="center" className="px-12 py-3">
                Title:Screen
              </th>
              <th align="center" className="px-12 py-3">
                Description:Screen
              </th>
              <th align="center" className="px-12 py-3">
                Title:Phone
              </th>
              <th align="center" className="px-12 py-3">
                Subtitle:Phone
              </th>
              <th align="center" className="px-12 py-3">
                Description:Phone
              </th>
              <th align="center" className="px-12 py-3">
                {" "}
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {contactdata.map((val: any, i: number) => {
              return (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <td align="center" className="px-2 py-4 ">
                    <input type="checkbox" />
                  </td>
                  <td align="center" className="px-2 py-4">
                    {i + 1}
                  </td>
                  <td align="center" className="px-2 py-4">
                    {val.titlemain}
                  </td>
                  <td align="center" className="px-2 py-4">
                    <div
                      className="break-words line-clamp-3 text-xs"
                      dangerouslySetInnerHTML={{ __html: val.maindescription }}
                    />
                  </td>
                  <td align="center" className="px-2 py-4">
                    {val.titlephone}
                  </td>
                  <td align="center" className="px-2 py-4">
                    {val.subtitlephone}
                  </td>
                  <td align="center" className="px-2 py-4">
                    <div
                      className="break-words line-clamp-3 text-xs"
                      dangerouslySetInnerHTML={{ __html: val.phonedescription }}
                    />
                  </td>

                  <td className="flex flex-row items-center justify-center mt-4 gap-2 text-blue-600">
                    <button
                      className="bg-blue-600 text-white px-4 py-2"
                      onClick={() => {
                        setOpenModel(true);
                        setUpdateData([val]);
                      }}
                    >
                      {" "}
                      Edit
                    </button>

                    <button
                      className="bg-red-600 text-white px-4 py-2"
                      onClick={() => {
                        DeleteData(val.id);
                      }}
                    >
                      {" "}
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Viewhomepage;
