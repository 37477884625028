import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import axios from "../Navigation/axios";

function View() {
  const [contactdata, setContactData] = useState<any>([]);

  const getContact = () => {
    try {
      axios
        .get("/logo")
        .then((res) => {
          setContactData([...res.data.data]);
        })
        .catch((err) => {
          toast.error("Could not fetch data");
        });
    } catch (error) {
      toast.error("Server Error");
    }
  };

  const DeleteData = (id: any) => {
    try {
      axios
        .delete(`/logo/${id}`)
        .then(() => {
          getContact();
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } catch (error) {
      toast.error("Server Error");
    }
  };

  useEffect(() => {
    getContact();
  }, []);

  return (
    <div className="flex flex-col gap-6 mt-10">
      <div className="font-lg text-3xl text-gray-400"> View Table Data</div>
      <table className="bg-green-200 p-5 w-full shadow-md shadow-green-400 ">
        <thead>
          <tr>
            <td
              align="center"
              className="px-6 py-3 flex items-center justify-center"
            >
              <input type="checkbox" />
            </td>
            <td align="center" className="px-8 py-3">
              S.N
            </td>
            <td align="center" className="px-12 py-3">
              Image C
            </td>
            <td align="center" className="px-12 py-3">
              Image B&W
            </td>
            <td align="center" className="px-12 py-3">
              Action
            </td>
          </tr>
        </thead>
        <tbody>
          {contactdata.map((val: any, i: number) => {
            return (
              <tr
                key={i}
                className="bg-white border dark:bg-gray-800 dark:border-gray-700"
              >
                <td align="center" className="px-2 py-4">
                  <input type="checkbox" />
                </td>
                <td align="center" className="px-2 py-4 ">
                  {i + 1}
                </td>
                <td align="center" className=" py-3 justify-center">
                  <img
                    src={`https://backend.lophoabroad.com/public/${val.imagec}`}
                    className="h-12 w-12"
                    alt="imageC"
                  />
                </td>
                <td align="center" className=" px-2 py-4 ">
                  <img
                    src={`https://backend.lophoabroad.com/public/${val.imagebw}`}
                    className="h-12 w-12 bg-gray-600"
                    alt="imagebw"
                  />
                </td>

                <td align="center" className="px-4 py-2">
                  <button
                    className="bg-red-600 text-white px-4 py-2"
                    onClick={() => {
                      DeleteData(val.id);
                    }}
                  >
                    {" "}
                    Delete
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default View;
