import axios from "axios";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { HiViewGridAdd } from "react-icons/hi";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import * as yup from "yup";
import { toast } from "react-hot-toast";

const schema = yup.object().shape({
  titlemain: yup.string().required("Required"),
  maindescription: yup.string().required("Required"),
  titlephone: yup.string().required("Required"),
  subtitlephone: yup.string().required(" Required"),
  phonedescription: yup.string().required("Required"),
});

function Homepage() {
  return (
    <div>
      <div className="flex flex-col gap-3 px-3 py-2">
        <div className="text-lg font-semibold "> Section Home Page</div>
        <Link
          to={"/viewhomepage"}
          className="flex flex-row gap-2 items-center h-fit w-fit px-6 py-2 border-b border-gray-800 text-lg"
        >
          <button>View</button>
          <span>
            <HiViewGridAdd />
          </span>
        </Link>
        <Formik
          initialValues={{
            titlemain: "",
            maindescription: "",
            titlephone: "",
            subtitlephone: "",
            phonedescription: "",
          }}
          validationSchema={schema}
          onSubmit={(values, { resetForm }) => {
            try {
              const formData = new FormData();
              formData.append("titlemain", values.titlemain);
              formData.append("maindescription", values.maindescription);
              formData.append("titlephone", values.titlephone);
              formData.append("subtitlephone", values.subtitlephone);
              formData.append("maindescription", values.phonedescription);
              axios
                .post(`https://backend.lophoabroad.com/homepage/`, values)
                .then((res) => {
                  toast.success("Homepage data sent successfully");
                  resetForm();
                })
                .catch((err) => {
                  toast.error(" Homepage data could not be added");
                });
            } catch (error) {
              toast.error("Server Error");
            }
          }}
        >
          {({ handleSubmit, setFieldValue, values }) => {
            return (
              <Form
                className="flex flex-col gap-2 px-2 "
                onSubmit={handleSubmit}
              >
                <div className=" flex flex-col gap-2">
                  <label className="text-md   font-semibold ">
                    {" "}
                    Title for main screen
                  </label>
                  <Field
                    className="py-2 w-full  px-2 border border-gray-400"
                    name="titlemain"
                    required
                  />
                  <ErrorMessage
                    className="text-red-500 flex flex-row px-4 "
                    name="titlemain"
                    component={"div"}
                  />
                </div>
                <div className=" flex flex-col gap-2">
                  <label className="text-md font-semibold w-96 ">
                    {" "}
                    Description for main screen
                  </label>
                  <ReactQuill
                    className="h-40 w-full overflow-scroll bg-white border border-gray-600 "
                    theme="snow"
                    value={values.maindescription}
                    onChange={(e) => {
                      setFieldValue("maindescription", e);
                    }}
                  />
                  <ErrorMessage
                    className="text-red-500 flex flex-row px-4 "
                    name="maindescription"
                    component={"div"}
                  />
                </div>
                <div className="grid grid-cols-2 gap-3">
                  <div className=" flex flex-col gap-2">
                    <label className="text-md font-semibold px-2 py-2  ">
                      {" "}
                      Title for phone screen
                    </label>
                    <Field
                      className="py-2 w-full px-2 border border-gray-400"
                      name="titlephone"
                      required
                    />
                    <ErrorMessage
                      className="text-red-500 flex flex-row px-4 "
                      name="titlephone"
                      component={"div"}
                    />
                  </div>
                  <div className=" flex flex-col gap-2">
                    <label className="text-md font-semibold  px-2 py-2   ">
                      {" "}
                      Subtitle for phone screen
                    </label>
                    <Field
                      className=" w-full py-2  px-2 border border-gray-400"
                      name="subtitlephone"
                      required
                    />
                    <ErrorMessage
                      className="text-red-500 flex flex-row px-4 "
                      name="subtitlephone"
                      component={"div"}
                    />
                  </div>
                </div>
                <div className=" flex flex-col gap-2">
                  <label className="text-md font-semibold py-2 ">
                    {" "}
                    Description for phone screen{" "}
                  </label>
                  <ReactQuill
                    className="h-40 w-full overflow-scroll bg-white border border-gray-600 "
                    theme="snow"
                    value={values.phonedescription}
                    onChange={(e) => {
                      setFieldValue("phonedescription", e);
                    }}
                  />
                  <ErrorMessage
                    className="text-red-500 flex flex-row px-4 "
                    name="phonedescription"
                    component={"div"}
                  />
                </div>
                <div className="flex bg-green-500 py-2 w-full h-fit justify-center items-center">
                  <input
                    type="submit"
                    className=" text-white w-fit h-fit px-6 py-2"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default Homepage;
