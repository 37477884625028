import { Formik, Form, ErrorMessage } from "formik";
import axios from "axios";
import Viewicons from "../Other/ViewIcons";
import * as yup from "yup";
import { toast } from "react-hot-toast";

const schema = yup.object().shape({
  title: yup.string().required("Required"),
  image: yup.string().required("Required"),
});

function Icons() {
  return (
    <div>
      <div>
        {" "}
        <div className=" text-2xl text-gray-400">Add Icons</div>
        <Formik
          initialValues={{
            title: "",
            image: "",
          }}
          validationSchema={schema}
          onSubmit={(values, { resetForm }) => {
            try {
              const formData = new FormData();
              formData.append("title", values.title);
              formData.append("image", values.image);

              axios
                .post("https://backend.lophoabroad.com/icons/", formData)
                .then((res) => {
                  toast.success("Icons sent successfully");
                  resetForm();
                })
                .catch((err) => {
                  toast.error(" Icons could not be added");
                });
            } catch (error) {
              toast.error("Server Error");
            }
          }}
        >
          {({ handleSubmit, setFieldValue }) => {
            return (
              <div className="flex items-center justify-center h-full w-full">
                <Form
                  onSubmit={handleSubmit}
                  className="flex  h-fit flex-col justify-center  bg-green-100 px-12 py-6 w-1/2 gap-4 shadow-md shadow-gray-600"
                >
                  <div className="flex flex-row gap-3 items-center">
                    <label className="text-md font-semibold w-24">Title</label>
                    <input
                      className=" px-2 py-1 w-full border-2 border-gray-400"
                      type="text"
                      onChange={(e: any) => {
                        setFieldValue("title", e.target.value);
                      }}
                    />
                    <ErrorMessage
                      className="text-red-500 flex flex-row px-4 "
                      name="title"
                      component={"div"}
                    />
                  </div>
                  <div className="flex flex-row gap-4 items-center">
                    <label className=" w-24 font-semibold "> Image</label>
                    <input
                      type="file"
                      onChange={(e: any) => {
                        setFieldValue("image", e.target.files[0]);
                      }}
                    />
                    <ErrorMessage
                      className="text-red-500 flex flex-row px-4 "
                      name="image"
                      component={"div"}
                    />
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="h-8 w-full  bg-green-600 drop-shadow-md text-white"
                    >
                      Submit
                    </button>
                  </div>{" "}
                </Form>
              </div>
            );
          }}
        </Formik>
      </div>
      <div>
        <Viewicons />
      </div>
    </div>
  );
}
export default Icons;
