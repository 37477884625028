import { Field, Form, Formik } from "formik";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-hot-toast";

function Courcespop({
  UpdateData,
  closeModel,
  getContact,
}: {
  UpdateData: any;
  closeModel: any;
  getContact: any;
}) {
  console.log(UpdateData);

  return (
    <div>
      <div className="h-full w-full bg-gradient-to-r from-transparent to-green-50  justify-center  flex  absolute  ">
        <Formik
          initialValues={{
            title: UpdateData[0].title,
            subheading: UpdateData[0].subheading,
            description: UpdateData[0].description,
            image: UpdateData[0].image,
            featured: UpdateData[0].featured,
          }}
          onSubmit={(values, { resetForm }) => {
            console.log(values);
            try {
              const formData = new FormData();
              formData.append("title", values.title);
              formData.append("subheading", values.subheading);
              formData.append("description", values.description);
              formData.append("image", values.image);
              formData.append("featured", values.featured);
              axios
                .patch(
                  `https://backend.lophoabroad.com/course/${UpdateData[0].title}`,
                  formData
                )
                .then((res) => {
                  toast.success("Update successful");
                  resetForm();
                  closeModel(false);
                  getContact();
                })
                .catch((err) => {
                  toast.error("Could not update data");
                });
            } catch (error) {
              toast.error("Server error");
            }
          }}
        >
          {({ handleSubmit, setFieldValue, values }) => {
            return (
              <Form
                onSubmit={handleSubmit}
                className=" flex flex-col gap-5 h-fit w-fit px-10 py-4 bg-gray-200 shadow-lg shadow-gray-700"
              >
                <div>
                  <label
                    htmlFor="title"
                    className=" text-md font-medium text-gray-900 dark:text-white"
                  >
                    Title
                  </label>
                  <Field
                    type="text"
                    name="title"
                    id="title"
                    className="bg-gray-50 px-2 border border-gray-30 text-gray-900 sm:text-sm  w-full h-8 "
                  />
                </div>
                <div>
                  <label
                    htmlFor="subheading"
                    className="block mb-2 text-md font-medium text-gray-900 dark:text-white"
                  >
                    Sub-Heading
                  </label>
                  <Field
                    type="text"
                    name="subheading"
                    id="subheading"
                    className="bg-gray-50 px-2 border w-full h-8 border-gray-300 text-gray-900 text-xm  "
                  />
                </div>
                <div>
                  <label
                    htmlFor="description"
                    className="block mb-2 text-md font-medium text-gray-900 dark:text-white"
                  >
                    Description
                  </label>
                  <ReactQuill
                    className="h-44 w-full overflow-scroll bg-white  border border-gray-600 "
                    theme="snow"
                    value={values.description}
                    onChange={(e) => {
                      setFieldValue("description", e);
                    }}
                  />
                </div>
                <div>
                  <label
                    htmlFor="image"
                    className="block mb-2 text-md font-medium text-gray-900 dark:text-white"
                  >
                    Image
                  </label>
                  <input
                    type="file"
                    onChange={(e: any) => {
                      setFieldValue("image", e.target.files[0]);
                    }}
                    name="image"
                    id="image"
                    className=" text-gray-900 rounded-lg "
                  />
                </div>
                <div>
                  <label htmlFor="featured">Featured</label>
                  <select
                    name="featured"
                    id="featured"
                    onChange={(e) => setFieldValue("featured", e.target.value)}
                  >
                    <option value="select any one">Select any one</option>
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                </div>

                <div className="w-full flex h-fit justify-center items-center">
                  <input
                    type="submit"
                    name="submit"
                    className="bg-gray-700 w-1/2 shadow-md shadow-gray-500 text-white px-12 py-2  "
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default Courcespop;
