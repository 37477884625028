import { BsSearch } from "react-icons/bs";
import { RiMessage2Fill } from "react-icons/ri";
import { BsBellFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

function Toolbar() {
  const navigate = useNavigate();

  return (
    <div className="  top-0 left-0 w-full">
      <div className="h-16 w-full p-4 justify-between  flex items-center bg-green-900">
        <div className="h-8 w-1/4 flex items-center px-4 bg-white gap-4 rounded-md text-gray-500">
          <BsSearch />
          <input
            type="text"
            placeholder="search..."
            className="h-full outline-none w-full bg-transparent"
          />
        </div>

        <div className="flex flex-row gap-4 items-center text-white mr-8">
          <RiMessage2Fill />
          <BsBellFill />

          <div className="flex flex-col text-white">
            <div className="text-sm">
              <button
                onClick={() => {
                  localStorage.removeItem("token");
                  navigate(0);
                }}
              >
                Log Out
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Toolbar;
