import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";

type Props = {};
const fields = [
  {
    name: "name",
    type: "text",
  },
  {
    name: "location",
    type: "text",
  },
  {
    name: "phone",
    type: "text",
  },
  {
    name: "telephone",
    type: "text",
  },
  {
    name: "email",
    type: "text",
  },
];
const Branch = (props: Props) => {
  const [tab, setTab] = useState<"update" | "add" | "null">("null");
  const [id, setId] = useState<string | number | null>(null);
  const [data, setData] = useState<any>();

  const UpdateModal: React.FC = () => {
    return (
      <Formik
        initialValues={{
          name: "",
          location: "",
          phone: "",
          telephone: "",
          email: "",
        }}
        // validationSchema={schema}
        onSubmit={(values) => {
          console.log(values);
          try {
            axios
              .patch(`https://backend.lophoabroad.com/branch/${id}`, values)
              .then((res) => {
                toast.success("Branch updated successfully");
                getData();
              })
              .catch((err) => {
                toast.error("Branch could not be updated");
              });
          } catch (error) {
            console.log(error);
          }
        }}
      >
        {({ handleSubmit }) => {
          return (
            <Form
              onSubmit={handleSubmit}
              className=" border w-fit px-2 bg-white text-black shadow-lg shadow-gray-800 "
            >
              {fields &&
                fields.map((item, i) => {
                  return (
                    <div>
                      <label>{item.name}</label>
                      <Field
                        name={item.name}
                        type={item.type}
                        className={`border border-black`}
                      />
                    </div>
                  );
                })}
              <input
                type="submit"
                name="submit"
                className="border h-8 w-32 bg-green-700 text-white"
              />
            </Form>
          );
        }}
      </Formik>
    );
  };

  const AddModal: React.FC = () => {
    return (
      <Formik
        initialValues={{
          name: "",
          location: "",
          phone: "",
          telephone: "",
          email: "",
        }}
        // validationSchema={schema}
        onSubmit={(values) => {
          console.log(values);
          try {
            axios
              .post(`https://backend.lophoabroad.com/branch/`, values)
              .then((res) => {
                toast.success("Branch added successfully");
                getData();
              })
              .catch((err) => {
                toast.error("Branch could not be added");
              });
          } catch (error) {
            console.log(error);
          }
        }}
      >
        {({ handleSubmit }) => {
          return (
            <Form
              onSubmit={handleSubmit}
              className=" border w-fit px-2 bg-white text-black shadow-lg shadow-gray-800 "
            >
              {fields &&
                fields.map((item, i) => {
                  return (
                    <div>
                      <label>{item.name}</label>
                      <Field
                        name={item.name}
                        type={item.type}
                        className={`border border-black`}
                      />
                    </div>
                  );
                })}
              <input
                type="submit"
                name="submit"
                className="border h-8 w-32 bg-green-700 text-white"
              />
            </Form>
          );
        }}
      </Formik>
    );
  };
  const getData = () => {
    try {
      axios
        .get("https://backend.lophoabroad.com/branch")
        .then((res) => {
          setData(res.data.data);
        })
        .catch((err): void => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const DeleteData = (id: any) => {
    try {
      axios
        .delete(`https://backend.lophoabroad.com/branch/${id}`)
        .then((res) => {
          console.log(res);
          getData();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="relative h-screen">
      {/* modals */}
      {tab !== "null" && (
        <div
          onClick={() => setTab("null")}
          className="fixed bg-black bg-opacity-50 top-0 left-0 right-0 bottom-0"
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="fixed bg-white top-1/2 left-1/2 right-1/2 bottom-1/2"
          >
            {tab === "add" ? (
              <AddModal />
            ) : tab === "update" ? (
              <UpdateModal />
            ) : null}
          </div>
        </div>
      )}
      {/* this is button */}
      <div className="flex flex-row gap-8 py-2 px-2">
        <button
          className="w-32 h-10 bg-white shadow-md shadow-gray-400"
          onClick={() => {
            setTab("add");
            setId(null);
          }}
        >
          Add
        </button>
      </div>
      <div>
        <div className="overflow-scroll scroll">
          <table className="w-full text-gray-800">
            <thead className="py-2 text-sm text-gray-700 uppercase bg-green-200 dark:text-gray-600">
              <tr>
                <th align="center" scope="col" className="py-3 px-6">
                  S.N
                </th>
                <th align="center" scope="col" className="py-3 px-12">
                  NAME
                </th>
                <th align="center" scope="col" className="py-3 px-12">
                  LOCATION
                </th>
                <th align="center" scope="col" className="py-3 px-12">
                  PHONE
                </th>
                <th align="center" scope="col" className="py-3 px-12">
                  TELEPHONE
                </th>
                <th align="center" scope="col" className="py-3 px-12">
                  EMAIL
                </th>
                <th align="center" scope="col" className="py-3 px-12">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((val: any, i: number) => {
                  return (
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <td align="center" className="py-4 px-2">
                        {i + 1}
                      </td>
                      <td align="center" className="py-4 px-2">
                        {val.name}
                      </td>
                      <td align="center" className="py-4 px-2">
                        {val.location}
                      </td>
                      <td align="center" className="py-4 px-2">
                        {val.phone}
                      </td>
                      <td align="center" className="py-4 px-2">
                        {val.telephone}
                      </td>
                      <td align="center" className="py-4 px-2">
                        {val.email}
                      </td>
                      <td align="center" className="flex flex-row px-2 mt-2">
                        <button
                          className="py-2 px-4 mr-1 text-white bg-blue-600"
                          onClick={() => {
                            setTab("update");
                            setId(val.id);
                          }}
                        >
                          {" "}
                          Edit
                        </button>
                        <button
                          className="py-2 px-4 text-white bg-red-600"
                          onClick={() => {
                            DeleteData(val.id);
                          }}
                        >
                          {" "}
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Branch;
