import { useState, useEffect } from "react";
import axios from "axios";
import Countrypop from "../Pictures/Popups/Countrypop";
import { toast } from "react-hot-toast";

function Vteams() {
  const [openModel, setOpenModel] = useState(false);
  const [contactdata, setContactData] = useState<any>([]);
  const [UpdateData, setUpdateData] = useState<any>([]);

  const getContact = () => {
    try {
      axios
        .get("https://backend.lophoabroad.com/country/")
        .then((res) => {
          console.log(res);
          setContactData([...res.data.data]);
        })
        .catch((err) => {
          toast.error("Could not fetch data");
        });
    } catch (error) {
      toast.error("Server error");
    }
  };

  const DeleteData = (id: any) => {
    try {
      axios
        .delete(`https://backend.lophoabroad.com/country/${id}`)
        .then((res) => {
          console.log(res);
          getContact();
        })
        .catch((err) => {
          console.log("Could not fetch data");
        });
    } catch (error) {
      console.log("Server error");
    }
  };
  useEffect(() => {
    getContact();
  }, []);

  return (
    <div className="flex flex-col gap-6 ">
      <div className=" text-xl text-gray-400">View Table Data of Country</div>
      {openModel && (
        <Countrypop
          UpdateData={UpdateData}
          getContact={getContact}
          closeModel={setOpenModel}
        />
      )}
      <div className="overflow-scroll scroll ">
        <table className="bg-gray-300 p-5 w-full">
          <thead className="text-md">
            <tr>
              <th align="center" className="px-6 py-3">
                <input type="checkbox" />
              </th>
              <th align="center" className="px-8 py-3">
                S.N
              </th>
              <th className="px-12 py-3">Image</th>
              <th className="px-12 py-3">Title</th>
              <th className="px-12 py-3">Subtitle</th>
              <th className="px-6 py-3">Description</th>
              <th className="px-12 py-3">Featured</th>
              <th className="px-12 py-3">Action</th>
            </tr>
          </thead>
          <tbody>
            {contactdata.map((val: any, i: number) => {
              return (
                <tr
                  key={i}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <td align="center" className=" py-3">
                    <input type="checkbox" />
                  </td>
                  <td align="center" className=" py-3">
                    {i + 1}
                  </td>
                  <td align="center" className=" py-3">
                    <img
                      src={`https://backend.lophoabroad.com/public/${val.image}`}
                      className="h-12 w-12"
                      alt="image"
                    />
                  </td>
                  <td align="center" className="px-2 py-3">
                    {val.title}
                  </td>
                  <td align="center" className="px-2 py-3">
                    {val.subtitle}
                  </td>
                  <td align="center" className="w-fit py-3 px-2">
                    <div
                      className="break-words line-clamp-3 text-xs"
                      dangerouslySetInnerHTML={{ __html: val.description }}
                    />
                  </td>
                  <td align="center" className="px-2 py-3 text-sm">
                    {val.featured.toString()}
                  </td>

                  <td className="flex flex-row items-center justify-center mt-4 gap-2 text-blue-600">
                    <button
                      className="bg-blue-600 text-white px-4 py-2"
                      onClick={() => {
                        setOpenModel(true);
                        setUpdateData([val]);
                      }}
                    >
                      {" "}
                      Edit
                    </button>
                    <button
                      className="bg-red-600 text-white px-4 py-2"
                      onClick={() => {
                        DeleteData(val.id);
                      }}
                    >
                      {" "}
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default Vteams;
