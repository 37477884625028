import { Form, Formik, ErrorMessage } from "formik";
import axios from "axios";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
  username: yup.string().required("Username is required"),
  password: yup.string().required("Password is required"),
});

function Login() {
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-center mb-10 flex-col gap-6 mt-20">
      <div className="text-2xl mt-5 px-8 font-searil ">
        {" "}
        Login to Admin Panel
      </div>

      <Formik
        initialValues={{
          username: "",
          password: "",
        }}
        validationSchema={schema}
        onSubmit={(values) => {
          console.log(values);
          try {
            const formData = new FormData();
            formData.append("username", values.username);
            formData.append("password", values.password);
            axios
              .post(`https://backend.lophoabroad.com/projectreg/login`, values)
              .then((res) => {
                localStorage.setItem("token", res.data.token);
                navigate("/");
                console.log(res);
              })
              .catch((err) => {
                console.log(err);
              });
          } catch (error) {
            console.log(error);
          }
        }}
      >
        {({ handleSubmit, setFieldValue }) => {
          return (
            <Form
              onSubmit={handleSubmit}
              className=" flex items-center flex-col border-2 bg-gray-300 border-blue-200 shadow-lg shadow-gray-800 px-12 py-12 gap-4 "
            >
              <div>
                <label
                  htmlFor="username"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Username
                </label>
                <input
                  className="w-96 py-2 bg-gray-100"
                  type="text"
                  onChange={(e) => {
                    setFieldValue("username", e.target.value);
                  }}
                  name="username"
                  required
                />
                <ErrorMessage
                  className="text-red-500 flex flex-row px-4 "
                  name="username"
                  component={"div"}
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Password
                </label>
                <input
                  className="w-96 py-2 bg-gray-100"
                  type="type"
                  onChange={(e) => {
                    setFieldValue("password", e.target.value);
                  }}
                  name="password"
                  id="password"
                  required
                />
                <ErrorMessage
                  className="text-red-500 flex flex-row px-4 "
                  name="password"
                  component={"div"}
                />
              </div>

              <input
                type="submit"
                name="submit"
                value="Login"
                className="border  px-12 py-2 bg-blue-400 text-white"
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default Login;
